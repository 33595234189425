import AbstractView from 'views/abstract/AbstractView'

import ViewFinder from 'common/ViewFinder'

import Select from 'views/includes/Select'

const SearchResultsContent = AbstractView.extend({
  template: 'SearchResultsContent',

  modules: [
    Select
  ],

  sort: null,

  constructor (config = {}) {
    SearchResultsContent.__super__.constructor.call(this, config)

    this.sort = ViewFinder.findChildren(this, Select)[0]
  }
})

export default SearchResultsContent
