import AbstractViewPage from 'views/abstract/AbstractViewPage'

const NewsListing = AbstractViewPage.extend({
  page: 'NewsListing',

  constructor (config = {}) {
    NewsListing.__super__.constructor.call(this, config)
  }
})

export default NewsListing
