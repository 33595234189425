import assign from 'lodash.assign'

import Singleton from 'common/Singleton'
import Analytics from 'common/Analytics'
import Channel from 'common/Channel'
import Constants from 'common/Constants'
import MediaQueries from 'common/MediaQueries'

import { getElHeight } from 'utils/DOM'

import AbstractView from 'views/abstract/AbstractView'

const TIME_UPDATE_INTERVAL = 1000

const Footer = AbstractView.extend({
  template: 'Footer',

  events: {
    'click [data-app-tmpl=Button]': 'onButtonClick',
    'click .Footer-navList a': 'onLinkClick'
  },

  constructor (config = {}) {
    Footer.__super__.constructor.call(this, config)

    // this.time = this.query('[data-current-time]')

    // this.updateTime(this.time, new Date())

    this.prefooter = this.query('.Footer-prefooter')
    this.footerMain = this.query('.Footer-main')

    this.listenTo(Channel, Constants.EVENT_RESIZE, this.onResize.bind(this))
    this.listenTo(Channel, Constants.EVENT_SCROLL_END, this.onScroll.bind(this))
  },

  // updateTime (timeEl, date = new Date()) {
  //   const ampm = date.getHours() >= 12 ? 'pm' : 'am'
  //   const hour = (date.getHours() % 12) || 12
  //   const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  //   const timeStr = `${hour}:${minute}${ampm}`
  //
  //   timeEl.innerText = timeStr
  //
  //   setTimeout(this.updateTime.bind(this, timeEl), TIME_UPDATE_INTERVAL)
  // },

  onButtonClick (e) {
    Analytics.action('navigation', 'button', e.delegateTarget.innerText)
  },

  onLinkClick (e) {
    Analytics.action('navigation', 'footer-click', e.delegateTarget.innerText.trim())
  },

  fixFooterSize () {
    if (MediaQueries.isSmallerThanBreakpoint(MediaQueries.TABLETPORTRAIT)) {
      this.prefooter.removeAttribute('style')
      return
    }

    this.prefooter.style.marginBottom = `${Math.floor(getElHeight(this.footerMain))}px`
  },

  onScroll () {
    this.fixFooterSize()
  },

  onResize () {
    this.fixFooterSize()
  }
})

export default assign(Footer, Singleton)
