import throttle from 'lodash.throttle'

import AbstractView from 'views/abstract/AbstractView'

import Channel from 'common/Channel'
import Constants from 'common/Constants'
import MediaQueries from 'common/MediaQueries'

import SearchResultsBox from 'views/includes/SearchResultsBox'

const Search = AbstractView.extend({
  template: 'Search',

  events: {
    'focus [data-input]': 'onFocusIn',
    'blur [data-input]': 'onFocusOut',
    'keyup [data-input]': 'onKeyUp',
    'mouseenter': 'onMouseEnter',
    'mouseleave': 'onMouseLeave',
    'submit [data-form]': 'onFormSubmit'
  },

  resultsBox: null,

  mouseOver: false,

  timeout: null,

  constructor (config = {}) {
    this._bindClassMethods()

    Search.__super__.constructor.call(this, config)

    const searchConfig = JSON.parse(this.el.getAttribute('data-config'))

    this.input = this.query('[data-input]')
    this.form = this.query('[data-form]')
    this.resultsBox = new SearchResultsBox(searchConfig)
    this.registerSubview(this.resultsBox)

    this.listenTo(Channel, Constants.EVENT_CLOSE_MENU, () => {
      this.input.value = ''
      this.resultsBox.remove()
      this.el.classList.remove('is-active')
    })

    if (this.input.value.trim().length > 0) {
      this.el.classList.add('is-active')
    }

    document.addEventListener('click', this.removeResultsBox)
  },

  _bindClassMethods () {
    this.onKeyUp = throttle(this.onKeyUp.bind(this), 400)
    this.removeResultsBox = this.removeResultsBox.bind(this)
  },

  removeResultsBox () {
    if (!this.mouseOver) {
      this.resultsBox.remove()
      this.input.value = ''
      this.el.classList.remove('is-active')
    }
  },

  onMouseEnter (e) {
    this.mouseOver = true
  },

  onMouseLeave (e) {
    this.mouseOver = false
  },

  onFormSubmit (e) {
    if (this.input.value.trim().length < 3) {
      this.input.focus();
      e.preventDefault();
      return false
    }
  },

  onKeyUp (e) {
    const { value } = e.target

    if ((value.trim() === '' || value.trim().length < 3) ||
      Constants.SKIP_KEYS.indexOf(e.keyCode) !== -1) return

    // Channel.trigger(Constants.EVENT_SEARCH_KEYUP, value)
    this.resultsBox.search(value)
  },

  onFocusIn (e) {
    clearTimeout(this.timeout)

    this.el.classList.add('is-active')

    if (this.resultsBox.hasRendered) return

    this.timeout = setTimeout(this.renderSubview.bind(this, this.resultsBox), 400)
  },

  onFocusOut (e) {
    clearTimeout(this.timeout)

    if (this.mouseOver || MediaQueries.isSmallerThanBreakpoint(MediaQueries.DESKTOP)) return

    this.el.classList.remove('is-active')

    this.timeout = setTimeout(this.resultsBox.remove.bind(this.resultsBox), 0)
  },

  dispose () {
    document.removeEventListener('click', this.removeResultsBox)

    Search.__super__.dispose.call(this)
  }
})

export default Search
