import 'whatwg-fetch'
import assign from 'lodash.assign'

import AbstractView from 'views/abstract/AbstractView'
import Channel from 'common/Channel'
import Constants from 'common/Constants'
import Singleton from 'common/Singleton'
import ViewFinder from 'common/ViewFinder'

import Select from 'views/includes/Select'
import InputText from 'views/includes/InputText'

const ContactForm = AbstractView.extend({
  template: 'ContactForm',

  modules: [
    Select,
    InputText
  ],

  open: false,

  selects: [],
  inputs: [],

  form: null,
  submit: null,

  events: {
    'click [data-submit]': 'onSubmit',
    'click [data-close]': 'onCloseForm'
  },

  constructor (config = {}) {
    this._bindClassMethods()

    ContactForm.__super__.constructor.call(this, config)

    this.selects = ViewFinder.findChildren(this, Select)
    this.inputs = ViewFinder.findChildren(this, InputText)

    this.form = this.query('form')
    this.submit = document.querySelector('.ContactForm-send')

    if (this.selects.length > 0) {
      this.selects = this.selects.map(select => {
        return { name: select.realSelect.name, select }
      })
    }

    this.listenTo(Channel, Constants.EVENT_OPEN_CONTACT_FORM, this.onOpenForm)
  },

  _bindClassMethods () {
    this.onOpenForm = this.onOpenForm.bind(this)
  },

  onOpenForm (props = {}) {
    this.open = true

    if (Object.keys(props).length > 0) {
      this.fillForm(props)
    }
  },

  onCloseForm () {
    this.open = false

    Channel.trigger(Constants.EVENT_CLOSE_CONTACT_FORM)
  },

  fillForm (props) {
    Object.keys(props).forEach(key => {
      const val = props[key]
      const { select } = this.selects.find(selectObj => selectObj.name === key)

      select.setValue(val)
    })
  },

  onSubmit (e) {
    e && e.preventDefault()

    if (this.form.reportValidity && !this.form.reportValidity()) {
      return false
    }

    if (!this.submit.hasAttribute('disabled')) {
      this.submit.setAttribute('disabled', 'disabled')
      sendContactUsFormToEloqua();
    }
    return

    // const action = this.form.getAttribute('action')
    //
    // fetch(action, {
    //   method: 'POST',
    //   body: new FormData(this.form)
    // })
    //   .then(rsp => {
    //     if (rsp.status >= 200 && rsp.status < 300) {
    //       return rsp
    //     } else {
    //       const err = new Error(rsp.statusText)
    //       err.response = rsp
    //       throw err
    //     }
    //   })
    //   .then(rsp => rsp.json())
    //   .then(rsp => console.log(rsp))
    //   .catch(err => console.error(err))

    // return false
  }
})

export default assign(ContactForm, Singleton)
