import AbstractView from 'views/abstract/AbstractView'

import Channel from 'common/Channel'
import Constants from 'common/Constants'

const ContactButton = AbstractView.extend({
  template: 'ContactButton',

  events: {
    'click': 'onClick'
  },

  constructor (config = {}) {
    ContactButton.__super__.constructor.call(this, config)

    //todo move this to the right place
    const getInTouch = document.querySelector('a[href*="#contact"]')
      if (getInTouch) {
        getInTouch.addEventListener('click', function (e) {
          if (e.preventDefault) {
            e.preventDefault()
          }
          if (e.stopPropagation) {
            e.stopPropagation()
          }
          Channel.trigger(Constants.EVENT_OPEN_CONTACT_FORM)
        })
      }
  },

  onClick () {
    Channel.trigger(Constants.EVENT_OPEN_CONTACT_FORM)
  }
})

export default ContactButton
