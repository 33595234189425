import AbstractView from 'views/abstract/AbstractView'

import Channel from 'common/Channel'
import Constants from 'common/Constants'
import { getElHeight, getElOffset } from 'utils/DOM'
import MediaQueries from 'common/MediaQueries'

const Ampersand = AbstractView.extend({
  template: 'Ampersand',

  constructor (config = {}) {
    this._bindClassMethods()

    Ampersand.__super__.constructor.call(this, config)

    this.parentEl = this.el.parentNode
    this.parentElHeight = getElHeight(this.parentEl)
    this.screenHeight = window.innerHeight

    this.shouldRun = this.el.getAttribute('data-run') === 'true'

    if (this.shouldRun) {
      this.listenTo(Channel, Constants.EVENT_RESIZE, this.onResize)
      this.listenTo(Channel, Constants.EVENT_SCROLL, this.onScroll)

      this.ypos = getElOffset(this.parentEl)
    }
  },

  _bindClassMethods () {
    this.onScroll = this.onScroll.bind(this)
    this.onResize = this.onResize.bind(this)
  },

  onScroll (scroll) {
    if ((this.screenHeight + scroll) < (this.ypos - 10) ||
      scroll > (this.ypos + this.parentElHeight)) return

    const delta = (this.ypos - scroll)
    const yOffset = 45 - ((8 * (delta / this.parentElHeight)) * -1)
    let transformStr = `translateY(-${yOffset}%)`

    if (MediaQueries.isSmallerThanBreakpoint(MediaQueries.TABLETPORTRAIT)) {
      transformStr = `${transformStr} translateX(-50%)`
    }

    this.el.style.transform = transformStr
  },

  onResize () {
    this.parentElHeight = getElHeight(this.parentEl)
    this.ypos = getElOffset(this.parentEl)
    this.screenHeight = window.innerHeight
  }
})

export default Ampersand
