import AbstractViewPage from 'views/abstract/AbstractViewPage'

const Error = AbstractViewPage.extend({
  page: 'Error',

  constructor (config = {}) {
    Error.__super__.constructor.call(this, config)
  }
})

export default Error
