import AbstractViewPage from 'views/abstract/AbstractViewPage'

const LeadershipTeam = AbstractViewPage.extend({
  page: 'LeadershipTeam',

  constructor (config = {}) {
    LeadershipTeam.__super__.constructor.call(this, config)
  }
})

export default LeadershipTeam
