import AbstractView from 'views/abstract/AbstractView'
import ViewAutoBinder from 'common/ViewAutoBinder'

import componentsPageModules from '../components'
import includesPageModules from '../includes'

const modules = componentsPageModules.concat(includesPageModules)

const AbstractViewPage = AbstractView.extend({

  modules,

  autobindOnInstantiation: false,

  _pageUrl: null,
  _title: null,

  constructor (config = { pageUrl: '', title: '' }) {
    this._pageUrl = config.pageUrl
    this._title = config.title

    AbstractViewPage.__super__.constructor.call(this, config)
  },

  show (cb) {
    ViewAutoBinder.bindView(this)

    if (cb && typeof cb === 'function') cb()
  },

  hide (cb) {
    this.el.classList.add('is-animating-out')

    setTimeout(() => {
      if (cb && typeof cb === 'function') cb()
    }, 500)
  }

})

export default AbstractViewPage
