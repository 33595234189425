import AbstractViewPage from 'views/abstract/AbstractViewPage'

const PublicationsListing = AbstractViewPage.extend({
  page: 'PublicationsListing',

  constructor (config = {}) {
    PublicationsListing.__super__.constructor.call(this, config)
  }
})

export default PublicationsListing
