import 'whatwg-fetch'
import assign from 'lodash.assign'
import template from 'lodash.template'
import View from 'ampersand-view'

// import Channel from 'common/Channel'
import Constants from 'common/Constants'

import tmpl from './index.tmpl'

const checkResponse = res => {
  if (res.status >= 200 && res.status <= 299) {
    return res
  } else {
    const err = new Error(res.statusText)
    err.response = res
    throw err
  }
}
const parseJSON = res => res.json()

const SearchResultsBox = View.extend({
  autoRender: true,

  events: {},

  template: template(tmpl),

  query: '',
  querying: false,

  popular: null,
  recent: null,
  response: null,

  config: {
    popular: '/api/sitecore/search/popular',
    endpoint: 'https://search-api.swiftype.com/api/v1/public/engines/suggest.json?engine_key=AiqmF7F8weNM4ryv-XqY&per_page=5',
    url: '/search'
  },

  constructor (config = {}) {
    SearchResultsBox.__super__.constructor.call(this, config)

    // this.config = assign({}, config)

    this.fetchPopular()
    this.fetchRecent()
  },

  fetchRecent () {
    this.recent = JSON.parse(window.localStorage.getItem(Constants.RECENT_SEARCHES)) || []

    this.recent = this.recent.map(r => {
      return {
        title: r,
        link: `${this.config.url}?query=${encodeURIComponent(r)}`
      }
    })
  },

  fetchPopular () {
    fetch(this.config.popular, {
      credentials: 'same-origin'
    })
      .then(checkResponse)
      .then(parseJSON)
      .then(res => {
        this.popular = res
      })
      .catch(err => { console.error(err) })
  },

  search (query) {
    let assettype_val, url;

    if (this.querying || query.trim() === this.query.trim()) return

    this.querying = true
    this.query = encodeURIComponent(query)
     //const url = `${this.config.endpoint}&q=${this.query}`
    assettype_val = document.querySelector('#assettype_value');
    url = this.config.endpoint + '&q=' + this.query;
    if (assettype_val && assettype_val.value) {
      url += '&filters[page][asset_type][]=' + assettype_val.value;
    }

    fetch(url, {
      // credentials: 'same-origin'
    })
      .then(res => { this.querying = false; return res })
      .then(checkResponse)
      .then(parseJSON)
      .then(res => {
        this.normaliseResponse(res)

        this.updateLocalStorage(query)

        this.render(false)
      })
      .catch(err => {
        this.querying = false
        console.error(err)
      })
  },

  normaliseResponse (results) {
    let res = []

    results.records.page.forEach(function (result) {
      res.push({
        title: result.title,
        category: result.category,
        link: result.url
      })
    })

    this.response = {
      results: res,
      resultsTotal: results.info.page.total_result_count,
      resultsLink: this.config.url + '?query=' + results.info.page.query
    }
  },

  updateLocalStorage (query) {
    // const { query } = results
    let recent = JSON.parse(window.localStorage.getItem(Constants.RECENT_SEARCHES)) || []

    if (recent.indexOf(query) < 0) {
      recent.push(query)

      if (recent.length > 3) {
        recent.shift()
      }
    }

    // recent = new Set(recent)

    window.localStorage.setItem(Constants.RECENT_SEARCHES, JSON.stringify(recent))
  },

  render (fade = true) {
    this.fetchRecent()

    SearchResultsBox.__super__.render.apply(this, arguments)

    if (fade) {
      setTimeout(this.show.bind(this), 20)
    } else {
      this.show()
    }

    this.hasRendered = true
  },

  show () {
    this.el.classList.add('SearchResultsBox--show')
  },

  remove () {
    this.response = null
    this.hasRendered = false

    SearchResultsBox.__super__.remove.call(this)
  }
})

export default SearchResultsBox
