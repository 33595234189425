import AbstractView from 'views/abstract/AbstractView'
import ViewFinder from 'common/ViewFinder'

import Ampersand from 'views/components/Ampersand'

const HeroHome = AbstractView.extend({
  template: 'HeroHome',

  modules: [
    Ampersand
  ],

  ampersand: null,

  constructor (config = {}) {
    HeroHome.__super__.constructor.call(this, config)

    this.ampersand = ViewFinder.findChildren(this, Ampersand)[0]
  }
})

export default HeroHome
