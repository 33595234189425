import assign from 'lodash.assign'
import debounce from 'lodash.debounce'

import AbstractView from 'views/abstract/AbstractView'
import AppRouter from 'router/AppRouter'
import Device from 'common/Device'
import MediaQueries from 'common/MediaQueries'
import GridGuides from 'common/GridGuides'
import Channel from 'common/Channel'
import Constants from 'common/Constants'
import Singleton from 'common/Singleton'
import UrlHelper from 'common/UrlHelper'
// import TimestampManager from 'common/TimestampManager'

import ScrollItemInView from 'common/ScrollItemInView'

import Header from 'views/core/Header'
import Footer from 'views/core/Footer'
import Wrapper from 'views/core/Wrapper'
import PageLoadProgress from 'views/core/PageLoadProgress'
import CookieBanner from 'views/components/CookieBanner'
import ContactForm from 'views/components/ContactForm'

const UTILITY_KEYS = [ 91, 17, 16 ]

const AppView = AbstractView.extend({
  template: 'App',

  body: null,

  currentDevice: undefined,

  dimensions: {
    width: 0,
    height: 0
  },

  events: {
    'click a': 'onLinkClick',
    'click .NavMask': 'onNavMaskClick'
  },

  keysDown: {},

  constructor () {
    console.log('~~ AppView::constructor called')

    // TimestampManager.getInstance().reset()

    this._bindClassMethods()

    AppView.__super__.constructor.call(this)
  },

  init () {
    Device.setup()
    MediaQueries.setup()

    if (process.env.NODE_ENV !== 'production') GridGuides.setup()

    this.body = document.querySelector('body')

    this.header = Header.getInstance()
    this.footer = Footer.getInstance()
    this.wrapper = Wrapper.getInstance()
    this.pageLoadProgress = new PageLoadProgress({ el: this.query(`[data-app-tmpl="${PageLoadProgress.prototype.template}"]`) })
    this.cookieBanner = new CookieBanner({ el: this.query(`[data-app-tmpl="${CookieBanner.prototype.template}"]`) })
    this.contactForm = ContactForm.getInstance()

    this
      .addChild(this.header)
      .addChild(this.footer)
      .addChild(this.wrapper)
      .addChild(this.pageLoadProgress)
      .addChild(this.cookieBanner)
      .addChild(this.contactForm)

    this.scrollItemInView = ScrollItemInView.getInstance({ appView: this })

    this.begin()
  },

  _bindClassMethods () {
    this.onScroll = this.onScroll.bind(this)
    this.onResize = this.onResize.bind(this)
    this.scrollUpdate = this.scrollUpdate.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onKeyUp = this.onKeyUp.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onPageOrAnchorChange = this.onPageOrAnchorChange.bind(this)
    this.onViewChangeComplete = this.onViewChangeComplete.bind(this)
    this.onDimensionsRequest = this.onDimensionsRequest.bind(this)
  },

  bindEvents () {
    // Call this here to make sure that we have app view dimensions
    // before we kick everything off
    this.onResize()

    this.onResize = debounce(this.onResize.bind(this), Constants.RESIZE_DEBOUNCE)

    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
    window.addEventListener('resize', this.onResize)
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('blur', this.onBlur)

    this.listenTo(Channel, Constants.EVENT_ANCHOR_CHANGED, this.onPageOrAnchorChange)
    this.listenTo(Channel, Constants.EVENT_CHANGE_VIEW_COMPLETE, this.onPageOrAnchorChange)
    this.listenTo(Channel, Constants.EVENT_CHANGE_VIEW_COMPLETE, this.onViewChangeComplete)
    this.listenTo(Channel, Constants.EVENT_OPEN_CONTACT_FORM, this.onOpenContactForm)
    this.listenTo(Channel, Constants.EVENT_CLOSE_CONTACT_FORM, this.onCloseContactForm)
  },

  bindReplies () {
    Channel.reply(Constants.REQUEST_VIEW_DIMENSIONS, this.onDimensionsRequest)
  },

  begin () {
    Channel.trigger(Constants.EVENT_APP_START)

    console.log('==> AppView::begin() called')

    this.bindEvents()
    this.bindReplies()

    this.onScroll()

    this.scrollItemInView.initialPageLoad()
  },

  onKeyDown (e) {
    this.keysDown[e.keyCode] = true
  },

  onKeyUp (e) {
    delete this.keysDown[e.keyCode]
  },

  onBlur () {
    this.keysDown = {}
  },

  onOpenContactForm () {
    this.body.classList.add('contactform-is-open')
  },

  onCloseContactForm () {
    this.body.classList.remove('contactform-is-open')
  },

  onPageOrAnchorChange () {},

  onViewChangeComplete () {
    if (this.announcement) {
      this.remove(this.announcement)
      this.announcement = null
    }
  },

  onDimensionsRequest () {
    return this.dimensions
  },

  onScroll (e) {
    this.lastScrollY = window.pageYOffset
    this.requestTick()
  },

  requestTick () {
    if (!this.ticking) {
      window.requestAnimationFrame(this.scrollUpdate)
      this.ticking = true
    }
  },

  scrollUpdate () {
    this.ticking = false

    clearTimeout(this.timerScroll)

    this.timerScroll = setTimeout(() => {
      Channel.trigger(Constants.EVENT_SCROLL_END, this.lastScrollY)
    }, Constants.SCROLL_END_THROTTLE)

    Channel.trigger(Constants.EVENT_SCROLL, this.lastScrollY)
  },

  onResize (e) {
    this.setViewportDimensions()

    const currentDevice = MediaQueries.getDeviceState()
    if (this.currentDevice !== currentDevice) Channel.trigger(Constants.EVENT_BREAKPOINT_CHANGE, currentDevice)
    this.currentDevice = currentDevice

    Channel.trigger(Constants.EVENT_RESIZE, this.dimensions)
  },

  onLinkClick (e) {
    const target = e.delegateTarget
    if (target.hasAttribute('data-ignore-link-click')) return

    const href = target.href
    if (!href) return false

    this.navigateToUrl(href, e)
  },

  onNavMaskClick () {
    this.body.classList.remove('contactform-is-open', 'menu-is-open')
  },

  navigateToUrl (href, e = null) {
    return;
    // console.log('ROUTE THAT', href)
    //
    // if (Modernizr.app_page_transitions &&
    //   this.noUtilityKeysPressed() &&
    //   UrlHelper.isSafeUrl(href)) {
    //   e.preventDefault()
    //
    //   const appRouter = AppRouter.getInstance()
    //   appRouter.navigateTo(href)
    // } else if (!UrlHelper.isSafeUrl(href)) {
    //   if (e) e.preventDefault()
    //   window.open(href)
    // }
  },

  noUtilityKeysPressed () {
    return !UTILITY_KEYS.filter(key => this.keysDown[key]).length
  },

  setViewportDimensions () {
    const width = this.getWindowWidth()
    const height = this.getWindowHeight()

    this.dimensions = { width, height }
  },

  getWindowWidth () {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  },

  getWindowHeight () {
    return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  }
})

export default assign(AppView, Singleton)
