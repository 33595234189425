import template from 'lodash.template'

import AbstractView from 'views/abstract/AbstractView'

import Select from 'views/includes/Select'

import ViewFinder from 'common/ViewFinder'

const CountrySelect = AbstractView.extend({
  template: 'CountrySelect',

  modules: [
    Select
  ],

  dropdown: null,
  countrySpan: null,

  flagTmpl: `<div class="Flag Flag--<%= value %> u-mr-12">
      <div class="Flag-spacer"></div>
      <img class="Flag-sprite" src="/assets/img/flags.png" alt="Flags">
    </div> <%= value %>`,

  constructor () {
    this._bindClassMethods()

    CountrySelect.__super__.constructor.call(this)

    this.flagTmpl = template(this.flagTmpl)

    this.flag = this.query('.Flag')
    this.countrySpan = this.query('.CountrySelect-country')

    this.dropdown = ViewFinder.findChildren(this, Select)[0]

    if (this.dropdown) {
      this.dropdown.options.forEach(opt => {
        const value = opt.innerHTML.trim()
        opt.innerHTML = this.flagTmpl({ value })
      })

      this.current = this.dropdown.realOptions.filter(opt => opt.selected === true)
      this.current = this.current[0] ? this.current[0].innerText : 'GLOBAL'

      this.setTextLabel()
    }

    this._bindEvents()
  },

  _bindClassMethods () {
    this.onChange = this.onChange.bind(this)
  },

  _bindEvents () {
    this.dropdown.realSelect.addEventListener('change', this.onChange)
  },

  setTextLabel () {
    this.countrySpan.innerText = this.current
  },

  onChange (e) {
    const url = e.target.value

    this.flag.classList.remove(`Flag--${this.current}`)

    this.current = this.dropdown.realOptions
      .filter(opt => opt.value === e.target.value)[0].innerText

    this.flag.classList.add(`Flag--${this.current}`)

    this.setTextLabel()

    setTimeout(_ => {
      window.location = url
    }, 100)
  },

  dispose () {
    this.dropdown.realSelect.removeEventListener('change', this.onChange)

    CountrySelect.__super__.dispose.call(this)
  }
})

export default CountrySelect
