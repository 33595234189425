import random from 'lodash.random'

import AbstractView from 'views/abstract/AbstractView'

import Channel from 'common/Channel'
import Constants from 'common/Constants'
import { setTransform } from 'utils/DOM'

const INITIAL_LOAD_PERCENTAGE = 15

const PageLoadProgress = AbstractView.extend({
  template: 'PageLoadProgress',

  currentLoaderProgress: 0,
  fakeLoaderTimer: null,

  constructor (config = {}) {
    this._bindClassMethods()
    PageLoadProgress.__super__.constructor.call(this, config)
    this.bindEvents()
  },

  _bindClassMethods () {
    this.onPageLoadStart = this.onPageLoadStart.bind(this)
    this.onPageLoadProgress = this.onPageLoadProgress.bind(this)
    this.onPageLoadEnd = this.onPageLoadEnd.bind(this)
    this.onPageLoadAbort = this.onPageLoadAbortOrError.bind(this)
  },

  bindEvents () {
    this.listenTo(Channel, Constants.EVENT_PAGE_LOAD_START, this.onPageLoadStart)
    this.listenTo(Channel, Constants.EVENT_PAGE_LOAD_PROGRESS, this.onPageLoadProgress)
    this.listenTo(Channel, Constants.EVENT_PAGE_LOAD_END, this.onPageLoadEnd)
    this.listenTo(Channel, Constants.EVENT_PAGE_LOAD_ABORT, this.onPageLoadAbortOrError)
    this.listenTo(Channel, Constants.EVENT_PAGE_LOAD_ERROR, this.onPageLoadAbortOrError)
  },

  onPageLoadStart () {
    this.el.classList.add('is-visible')

    this.onPageLoadProgress(INITIAL_LOAD_PERCENTAGE)
  },

  onPageLoadProgress (percentageComplete) {
    clearTimeout(this.fakeLoaderTimer)

    if (percentageComplete > this.currentLoaderProgress) {
      const scale = Math.min((percentageComplete / 100), 1)

      this.currentLoaderProgress = percentageComplete

      setTransform(this.el, `scaleX(${scale})`)
    }

    this._startFakeProgressTimer()
  },

  onPageLoadEnd () {
    clearTimeout(this.fakeLoaderTimer)

    setTransform(this.el, `scaleX(1)`)

    setTimeout(() => {
      this.resetPageLoader(false)
    }, 200)
  },

  onPageLoadAbortOrError () {
    this.resetPageLoader()
  },

  resetPageLoader (immediate = true) {
    this.el.classList.remove('is-visible')
    this.currentLoaderProgress = 0

    if (immediate) {
      this.ui.$pageLoader.css({ "transform": "scaleX(0)" });
      setTransform(this.el, `scaleX(0)`)
    } else {
      setTimeout(() => setTransform(this.el, `scaleX(0)`), 100)
    }
  },

  _startFakeProgressTimer () {
    this.fakeLoaderTimer = setTimeout(
      this.onPageLoadProgress.bind(this,
        random(this.currentLoaderProgress, this.currentLoaderProgress + random(0, 10))
      ),
      random(100, 600)
    )
  }
})

export default PageLoadProgress
