import AbstractView from 'views/abstract/AbstractView'

const CookieBanner = AbstractView.extend({
  template: 'CookieBanner',

  COOKIE_NAME: 'hasConsent',
  COOKIE_TIMEOUT: 33696000000, // 13 months in milliseconds
  VISIBLE_CLASS: 'CookieBanner--is-visible',
  SET_COOKIE_DELAY: 5000,

  events: {
    'click [data-close]': 'onCloseBtnClick'
  },

  constructor (config = {}) {
    CookieBanner.__super__.constructor.call(this, config)
  },

  init () {
    setTimeout(this.checkForCompliance.bind(this), 4000)
  },

  checkForCompliance () {
    if (this.hasConsent()) return false

    this.showBanner()
    this.setCookieTimer = setTimeout(this.setCookie.bind(this), this.SET_COOKIE_DELAY)
  },

  hasConsent () {
    return (document.cookie.indexOf(this.COOKIE_NAME + '=true') > -1)
  },

  setCookie () {
    const date = new Date()
    date.setTime(date.getTime() + this.COOKIE_TIMEOUT)
    document.cookie = this.COOKIE_NAME + '=true;expires=' + date.toGMTString() + ';path=/'
  },

  onCloseBtnClick () {
    clearTimeout(this.setCookieTimer)
    this.hideBanner()
    this.setCookie()
  },

  showBanner () {
    setTimeout(() => {
      this.el.classList.add(this.VISIBLE_CLASS)
    }, 100)
  },

  hideBanner () {
    setTimeout(() => {
      this.el.classList.remove(this.VISIBLE_CLASS)
    }, 300)
  }
})

export default CookieBanner
