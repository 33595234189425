import AbstractView from 'views/abstract/AbstractView'

const GlobalLocationsMap = AbstractView.extend({
  template: 'GlobalLocationsMap',

  modules: [],

  sort: null,

  constructor(config = {}) {
    GlobalLocationsMap.__super__.constructor.call(this, config)


    jQuery(function ($) {
      "use strict";
      let locations = $(".location-listings").find(".location-link[data-lat][data-long]"),
        markerImage = $(".locations-map").data('marker'),
        markers = [],
        selected_countries = [],
        selectedRegions = [],
        country_to_region = {},
        touch = Modernizr.touch && $(window).width() < 1025,
        ios = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

      locations.each(function () {
        let location = $(this),
          lat = location.data("lat"),
          long = location.data("long"),
          title = location.text(),
          link = location.attr("href"),
          marker = {latLng: [lat, long], link: link, name: title};

        markers.push(marker);
      });

      let region_countries = {
        northamerica: ["CA", "US", "PR", "GL"],
        latinamerica: ["AR", "BR", "CO", "MX", "PA", "BO", "CL", "EC", "FK", "GY", "GF", "PY", "PE", "SR", "UY", "VE", "CR", "NI", "HN", "SV", "GT", "BZ", "BS", "CU", "JM", "DO", "HT"],
        asiapacific: ["AU", "CN", "IN", "ID", "JP", "PH", "AF", "BD", "BT", "BN", "KH", "CY", "KP", "KR", "KG", "LA", "MY", "MN", "NP", "PK", "QA", "LK", "TW", "TJ", "TH", "TM", "UZ", "VN", "PS", "MM", "PG", "NZ", "NC", "SB", "FJ", "TL", "VU"],
        eme: [
          "EH", "XK",
          "BE", "FR", "ES", "GB", "IE", "DE", "RU", "ZA", "AM", "AE", "AZ", "QA", "AL", "AT", "IL", "BY", "GE", "JO", "KW", "KZ", "LB", "BA", "BG", "HR", "IR", "CY", "IQ", "CZ", "DK", "EE", "FI", "GR", "HU", "IS", "IT", "LV", "LT", "LU", "MK", "MD", "OM", "NL", "NO", "TR", "PL", "SY", "PT", "SA", "RO", "RU", "RS", "SK", "SI", "SE", "CH", "YE", "UA", "RS", "ME", "DZ", "AO", "BJ", "BW", "BF", "BI", "CM", "CF", "TD", "CG", "DJ", "EG", "GQ", "ER", "ET", "GR", "GM", "GH", "GW", "GN", "CI", "KE", "LS", "LR", "LY", "MG", "MW", "ML", "MR", "MA", "MZ", "NA", "NE", "NG", "RW", "SN", "SL", "SO", "SD", "SZ", "TZ", "TG", "TN", "UG", "CD", "ZM", "TZ", "ZW", "SS", "CD", "GA", "DZ", "XS"]
      };

      for (let k in region_countries) {
        for (let l = 0; l < region_countries[k].length; l++) {
          country_to_region[region_countries[k][l]] = k;
        }
      }

      $(".location-world-map:eq(0)").vectorMap({
        map: "world_merc",
        regionStyle: {
          initial: {fill: "#f6f6f7" },
          hover: {fill: "#42bcea", "fill-opacity": 1},
          focus: {fill: "#42bcea", "fill-opacity": 1},
          selected: {fill: "#465661"}
        },
        normalizeFunction: "polynomial",
        hoverOpacity: 1,
        hoverColor: !1,
        // backgroundColor: "#e8f5f9",
        backgroundColor: "",
        markers: markers,
        markerStyle: {
          initial: {
            image: markerImage // Absolute path should be place in here.
          }
        },
        zoomOnScroll: false,
        onMarkerOver: function (event, index) {
          let $label = $(".marker-link-label");
          let $mapMarker = $("[data-index=" + index + "]");
          let $coords = {
            left: $($mapMarker).attr('x') - 2 + "px",
            top: $($mapMarker).attr('y') - 2 + "px"
          };

          $label.children('a').attr('href', markers[index].link);
          $label.find('.marker-name').text(markers[index].name);
          $label.css($coords).show();
        },

        onRegionOver: function (event, regionCode) {
          let d, map;

          if (regionCode in country_to_region) {
            d = country_to_region[regionCode];
            map = $(".location-world-map").vectorMap("get", "mapObject");

            if (d !== selectedRegions) {
              map.clearSelectedRegions();
              selectedRegions = d;
              map.setSelectedRegions(region_countries[d]);
            }
          }
          if (ios) {
            window.location.hash = country_to_region[regionCode];
          }
        },

        onRegionClick: function (event, code) {
          if (touch) {
            window.location.hash = country_to_region[code];
          } else {
            let map = $(".location-world-map").vectorMap("get", "mapObject");
            if (code in country_to_region) {
              let h = region_countries[country_to_region[code]];
              if (h !== selected_countries) {
                if ("northamerica" === country_to_region[code]) {
                  map.setFocus({
                    scale: 2.7,
                    x: .01,
                    y: .43,
                    animate: true
                  });
                } else {
                  map.setFocus({
                    regions: h,
                    animate: true
                  });
                  selected_countries = h;
                }
              } else {
                map.reset();
                selected_countries = [];
              }
            } else {
              map.reset();
              selected_countries = [];
            }
            $(".marker-link-label").hide()
          }

        }
      });

      // Initial map zoom.
      if (!ios) {
        let initial_state = {scale: 1.65, x: .4, y: .58};
        if ($(window).width() < 1024) {
          initial_state = {scale: 1.25, x: .3, y: .7};
        }
        $(".location-world-map").vectorMap("get", "mapObject").setFocus(initial_state);
      }

      $(".marker-link-label").on('mouseleave', function () {
        $(this).hide()
      });
    });
  }
})

export default GlobalLocationsMap
