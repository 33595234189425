import AbstractViewPage from 'views/abstract/AbstractViewPage'

const ReportDetail = AbstractViewPage.extend({
  page: 'ReportDetail',

  constructor (config = {}) {
    ReportDetail.__super__.constructor.call(this, config)
  }
})

export default ReportDetail
