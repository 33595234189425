import AbstractView from 'views/abstract/AbstractView'

import Constants from 'common/Constants'
import MediaQueries from 'common/MediaQueries'

import Ampersand from 'views/components/Ampersand'
import SearchResultsBox from 'views/includes/SearchResultsBox'

const InPageSearch = AbstractView.extend({
  template: 'InPageSearch',

  modules: [
    Ampersand
  ],

  events: {
    'focus [data-input]': 'onFocusIn',
    'blur [data-input]': 'onFocusOut',
    'keyup [data-input]': 'onKeyUp',
    'mouseenter [data-wrapper]': 'onMouseEnter',
    'mouseleave [data-wrapper]': 'onMouseLeave'
  },

  resultsBox: null,

  mouseOver: false,

  timeout: null,
  searchTimeout: null,

  constructor (config = {}) {
    this._bindClassMethods()

    InPageSearch.__super__.constructor.call(this, config)

    const searchConfig = JSON.parse(this.el.getAttribute('data-config'))

    this.input = this.query('[data-input]')
    this.inputWrapper = this.query('[data-wrapper]')
    this.form = this.query('[data-form]')
    this.resultsBox = new SearchResultsBox(searchConfig)
    this.registerSubview(this.resultsBox)

    if (this.input.value.trim().length > 0) {
      this.el.classList.add('is-active')
    }

    document.addEventListener('click', this.removeResultsBox)
  },

  _bindClassMethods () {
    this.onKeyUp = this.onKeyUp.bind(this)
    this.removeResultsBox = this.removeResultsBox.bind(this)
  },

  removeResultsBox () {
    if (!this.mouseOver) this.resultsBox.remove()
  },

  onMouseEnter (e) {
    this.mouseOver = true
  },

  onMouseLeave (e) {
    this.mouseOver = false
  },

  onFormSubmit (e) {
    if (this.input.value.trim().length < 3) return false
  },

  onKeyUp (e) {
    const { value } = e.target

    if ((value.trim() === '' || value.trim().length < 3) ||
      Constants.SKIP_KEYS.indexOf(e.keyCode) !== -1) return

    if (this.searchTimeout) clearTimeout(this.searchTimeout)

    this.searchTimeout = setTimeout(this.resultsBox.search.bind(this.resultsBox, value), 400)
  },

  onFocusIn (e) {
    clearTimeout(this.timeout)

    this.el.classList.add('is-active')

    if (this.resultsBox.hasRendered) return

    this.timeout = setTimeout(() => {
      this.renderSubview(this.resultsBox, this.inputWrapper)
    }, 400)
  },

  onFocusOut (e) {
    clearTimeout(this.timeout)

    if (this.mouseOver || MediaQueries.isSmallerThanBreakpoint(MediaQueries.DESKTOP)) return

    if (this.input.value.trim().length === 0) {
      this.el.classList.remove('is-active')
    }

    this.timeout = setTimeout(this.resultsBox.remove.bind(this.resultsBox), 0)
  },

  dispose () {
    document.removeEventListener('click', this.removeResultsBox)

    InPageSearch.__super__.dispose.call(this)
  }
})

export default InPageSearch
