import AbstractViewPage from 'views/abstract/AbstractViewPage'

const SearchResults = AbstractViewPage.extend({
  page: 'SearchResults',

  constructor (config = {}) {
    SearchResults.__super__.constructor.call(this, config)
  }
})

export default SearchResults
