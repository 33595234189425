import AbstractViewPage from 'views/abstract/AbstractViewPage'

const Industries = AbstractViewPage.extend({
  page: 'Industries',

  constructor (config = {}) {
    Industries.__super__.constructor.call(this, config)
  }
})

export default Industries
