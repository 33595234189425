import AbstractView from 'views/abstract/AbstractView'

import Channel from 'common/Channel'
import Constants from 'common/Constants'
import ViewFinder from 'common/ViewFinder'

import Ampersand from 'views/components/Ampersand'
import Select from 'views/includes/Select'

const GetStarted = AbstractView.extend({
  template: 'GetStarted',

  modules: [
    Ampersand,
    Select
  ],

  events: {
    'click [data-contact-form]': 'onContactButtonClick'
  },

  selects: null,

  constructor (config = {}) {
    GetStarted.__super__.constructor.call(this, config)

    this.selects = ViewFinder.findChildren(this, Select)
  },

  onContactButtonClick () {
    const populate = this.selects
      .map(select => select.getValue())
      .filter(val => val !== false)
      .reduce((val, obj) => {
        return Object.assign(obj, val)
      }, {})

    Channel.trigger(Constants.EVENT_OPEN_CONTACT_FORM, populate)
  }
})

export default GetStarted
