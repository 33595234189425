import Filter from '../Filter/index.tmpl.js'

export default `
  <div class="<%= obj.className || 'Select' %>--js">
    <div
      class="<%= obj.className || 'Select' %>-display"
      tabindex="0"
      data-display
    >
      <div class="<%= obj.className || 'Select' %>-displayInner p-2">
        <span class="<%= obj.className || 'Select' %>-display--default" data-display-default><%= label %></span>
        <span class="<%= obj.className || 'Select' %>-display--selected" data-display-selected></span>
      </div>

      <svg class="Icon Icon--chevron <%= obj.className || 'Select' %>-chevron" data-app-tmpl="Icon">
        <title>chevron</title>
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#chevron"></use>
      </svg>
    </div>
    <div class="<%= obj.className || 'Select' %>-content" data-content>
      <div class="<%= obj.className || 'Select' %>-contentInner" data-content-inner>
      <% if (obj.filter && obj.filter.show === true) { %>
        ${Filter}
      <% } %>
      <ul class="<%= obj.className || 'Select' %>-options">
        <% options.forEach(function(option) { %>
        <li
          class="<%= obj.className || 'Select' %>-item p-6"
          data-option="<%= option.value %>">
          <%= option.display %>
        </li>
        <% }) %>
      </ul>
      </div>
    </div>
  </div>
`
