import AbstractViewPage from 'views/abstract/AbstractViewPage'

const About = AbstractViewPage.extend({
  page: 'About',

  constructor (config = {}) {
    About.__super__.constructor.call(this, config)
  }
})

export default About
