import assign from 'lodash.assign'

import AbstractView from 'views/abstract/AbstractView'
import PageModel, { getPagePartials } from 'models/PageModel'
import Singleton from 'common/Singleton'
import Channel from 'common/Channel'
import Constants from 'common/Constants'
import UrlHelper from 'common/UrlHelper'
import Analytics from 'common/Analytics'

import HomeView from 'views/pages/Home'
import ServicesLanding from 'views/pages/ServicesLanding'
import ServicesCategory from 'views/pages/ServicesCategory'
import ServicesDetail from 'views/pages/ServicesDetail'
import ArticleDetail from 'views/pages/ArticleDetail'
import SearchResults from 'views/pages/SearchResults'
import GlobalLocations from 'views/pages/GlobalLocations'
import PeopleDetail from 'views/pages/PeopleDetail'
import LocationDetail from 'views/pages/LocationDetail'
import EventsListing from 'views/pages/EventsListing'
import EventDetail from 'views/pages/EventDetail'
import PublicationsListing from 'views/pages/PublicationsListing'
import NewsListing from 'views/pages/NewsListing'
import WebcastsListing from 'views/pages/WebcastsListing'
import About from 'views/pages/About'
import OurTeam from 'views/pages/OurTeam'
import LeadershipTeam from 'views/pages/LeadershipTeam'
import CaseStudiesListing from 'views/pages/CaseStudiesListing'
import CaseStudiesDetail from 'views/pages/CaseStudiesDetail'
import Industries from 'views/pages/Industries'
import Insights from 'views/pages/Insights'
import Table from 'views/pages/Table'
import NotFound from 'views/pages/NotFound'
import ReportListing from 'views/pages/ReportListing'
import ReportDetail from 'views/pages/ReportDetail'

// TODO: remove before launch
// import Components from 'views/pages/Components'

const pages = [
  HomeView,
  ServicesLanding,
  ServicesCategory,
  ServicesDetail,
  ArticleDetail,
  SearchResults,
  GlobalLocations,
  PeopleDetail,
  EventsListing,
  EventDetail,
  PublicationsListing,
  NewsListing,
  WebcastsListing,
  About,
  LocationDetail,
  OurTeam,
  LeadershipTeam,
  CaseStudiesListing,
  CaseStudiesDetail,
  Industries,
  Insights,
  Table,
  NotFound,
  ReportListing,
  ReportDetail,
]

// if (process.env.NODE_ENV !== 'production') pages.push(Components)

const Wrapper = AbstractView.extend({
  template: 'Wrapper',

  pages,

  activePageRequest: null,
  activePageModel: null,

  previousView: null,
  currentView: null,

  constructor () {
    this._bindClassMethods()
    Wrapper.__super__.constructor.call(this)
  },

  _bindClassMethods () {
    this.onHashChanged = this.onHashChanged.bind(this)
    this.onNewPageRequestDone = this.onNewPageRequestDone.bind(this)
    this.onPageModelRequest = this.onPageModelRequest.bind(this)
    this.getCurrentViewName = this.getCurrentViewName.bind(this)
  },

  init () {
    this.bindEvents()
    this.bindChannelReplies()
  },

  dispose () {
    Channel.stopReplying(Constants.REQUEST_PAGE_MODEL)
    Channel.stopReplying(Constants.REQUEST_INDEXED_BLOCKS)

    Wrapper.__super__.dispose.apply(this, arguments)
  },

  bindEvents () {
    this.listenTo(Channel, Constants.EVENT_HASH_CHANGED, this.onHashChanged)
    this.listenTo(Channel, Constants.EVENT_CHANGE_VIEW_COMPLETE, this.onChangeViewComplete)
  },

  bindChannelReplies () {
    Channel.reply(Constants.REQUEST_PAGE_MODEL, this.onPageModelRequest)
    Channel.reply(Constants.REQUEST_CURRENT_VIEW, this.getCurrentViewName)
  },

  onChangeViewComplete () {
    return this.getCurrentViewName()
  },

  getCurrentViewName () {
    return this.query('[data-app-page]').getAttribute('data-app-page')
  },

  onHashChanged (current, previous, params) {
    // first view - render view based on existing markup
    if (params.FIRST_ROUTE) return this._firstChangeView(current)

    Channel.trigger(Constants.EVENT_SHOW_GLOBAL_LOADING)

    this._initiateNewPageRequest(current, params)
  },

  onPageModelRequest () {
    return this.activePageModel
  },

  getViewByPageType (type) {
    const Views = this.pages.filter(page => page.prototype.page === type)
    const View = Views.length ? Views[0] : false

    // @TODO - create default view
    if (!View) {
      throw new Error('No view... wut? Add to the top of this file')
    }

    return View
  },

  _firstChangeView (current) {
    const document = window.document
    const page = this.query('[data-app-page]')
    const pagePartials = getPagePartials(page)
    const pageType = page.getAttribute('data-app-page')
    const pageUrl = UrlHelper.getFullPathFromRouteObject(current)
    const title = document.title
    const description = document.querySelector('meta[name="description"]') ? document.querySelector('meta[name="description"]').getAttribute('content') : null
    const pageModel = new PageModel({
      document,
      page,
      pagePartials,
      pageType,
      pageUrl,
      title,
      description})

    this.activePageModel = pageModel

    this.changeView(pageModel)
  },

  _postFirstChangeView (pageModel) {
    this.activePageModel = pageModel

    document.title = pageModel.get('title')

    Analytics.page(UrlHelper.getRelativeHref(this.activePageModel.get('pageUrl')))

    const page = pageModel.get('page')
    this.el.appendChild(page)

    if (Modernizr.safari) this._applySafariSrcsetFix(page)

    this.changeView(pageModel)
  },

  changeView (pageModel) {
    this.previousView = this.currentView

    const NewView = this.getViewByPageType(pageModel.get('pageType'))
    const el = pageModel.get('page')
    const pageUrl = pageModel.get('pageUrl')
    const title = pageModel.get('title')
    const newBodyClass = pageModel.get('document').querySelector('body').className
    const body = document.querySelector('body')

    body.className = newBodyClass

    this.currentView = new NewView({ el, pageUrl, title })
    this.addChild(this.currentView)

    this.transitionViews(this.previousView, this.currentView)
  },

  _initiateNewPageRequest (current, params = {}) {
    if (this.activePageRequest) this.activePageRequest.abort()

    let pageUrl = UrlHelper.getFullUrlFromRouteObject(current)

    const pageModel = new PageModel({ pageUrl })

    const { promise, xhr } = pageModel.fetch()

    this.activePageRequest = xhr
    promise
      .then(this.onNewPageRequestDone)
      .catch(this.onNewPageRequestFail)
  },

  onNewPageRequestDone (pageModel) {
    Channel.trigger(Constants.EVENT_HIDE_GLOBAL_LOADING)
    Channel.trigger(Constants.EVENT_HIDE_SEARCH)

    this._postFirstChangeView(pageModel)
    this.activePageRequest = null
  },

  onNewPageRequestFail (pageModel) {
    Channel.trigger(Constants.EVENT_HIDE_GLOBAL_LOADING)

    // fallback to default link behaviour
    window.location.reload()
  },

  transitionViews (from, to) {
    if (from === to) return

    if (!from && to) {
      to.show()
    } else if (from && to) {
      this._switchPages(from, to)
    }
  },

  _switchPages (from, to) {
    from.hide(() => {
      this.remove(from)

      Channel.trigger(Constants.EVENT_CHANGE_VIEW_START)
      window.scrollTo(0, 0)
      to.show()

      window.requestAnimationFrame(() => {
        Channel.trigger(Constants.EVENT_CHANGE_VIEW_COMPLETE)
      })
    })
  },

  _applySafariSrcsetFix (page) {
    Array.prototype.slice
      .call(page.querySelectorAll('img'))
      .forEach(img => { img.src = `${img.src}?safari_srcset_fix` })
  }

})

export default assign(Wrapper, Singleton)
