import AbstractViewPage from 'views/abstract/AbstractViewPage'

const ServicesLanding = AbstractViewPage.extend({
  page: 'ServicesLanding',

  constructor (config = {}) {
    ServicesLanding.__super__.constructor.call(this, config)
  }
})

export default ServicesLanding
