import throttle from 'lodash.throttle'

import AbstractView from 'views/abstract/AbstractView'

import AppView from 'views/AppView'

import Channel from 'common/Channel'
import Constants from 'common/Constants'
import { getElHeight, queryAll } from 'utils/DOM'
import MediaQueries from 'common/MediaQueries'

const InPageSubNav = AbstractView.extend({
  template: 'InPageSubNav',

  ORG_OFFSET: 220,
  SCROLL_THRESHOLD: 180,
  RESPONSIVE_THRESHOLD: {
    'default': 90,
    'tabletportrait': 210,
    'tabletlandscape': 210
  },

  reposition: false,

  events: {
    // 'click [data-toggle-section]': 'onToggleSectionClick',
    'click [data-mobile-toggle]': 'onMobileToggle'
  },

  constructor (config = {}) {
    this._bindClassMethods()

    InPageSubNav.__super__.constructor.call(this, config)

    this.listenTo(Channel, Constants.EVENT_SCROLL, this.onScroll)
    this.listenTo(Channel, Constants.EVENT_RESIZE, this.onResize)

    this.appView = AppView.getInstance()

    this.content = this.query('.InPageSubNav-content')
    this.navItems = this.queryAll('.InPageSubNav-list .InPageSubNav-item')

    if (MediaQueries.isSmallerThanBreakpoint(MediaQueries.DESKTOP)) {
      this.reposition = false
    }

    // expand if active
    // let active = this.query('.InPageSubNav-item.is-active')
    // if (active) {
    //   active.click()
    // }

    this.cacheDimensions()
  },

  _bindClassMethods () {
    this.onScroll = this.onScroll.bind(this)
    this.onResize = this.onResize.bind(this)
    this.cacheDimensions = throttle(this.cacheDimensions.bind(this), 250)
  },

  onMobileToggle () {
    this.el.classList.toggle('InPageSubNav--showMobile')
    this.appView.body.classList.toggle('u-of-hidden')
    this.appView.body.classList.toggle('show-subnav')
  },

  // onToggleSectionClick (event) {
  //   // const targetEl = event.delegateTarget.parentNode
  //   const targetEl = event.delegateTarget
  //   const targetList = targetEl.querySelector('ul')
  //
  //   if (event.target !== targetEl) {
  //     if (!event.target.classList.contains('InPageSubNav-itemSubNav')) {
  //       let ancestor = this.findAncestor(event.target, 'InPageSubNav-itemSubNav');
  //       if (ancestor) {
  //         return
  //       }
  //     } else {
  //       return
  //     }
  //   }
  //
  //   if (targetEl.classList.contains('is-expanded')) {
  //     targetList.removeAttribute('style')
  //     targetEl.classList.remove('is-expanded')
  //     return
  //   }
  //
  //   if (targetList) {
  //     this.navItems
  //       .filter(item => item.classList.contains('is-expanded'))
  //       .forEach(item => {
  //         item.querySelector('ul').removeAttribute('style')
  //         item.classList.remove('is-expanded')
  //       })
  //
  //     const innerHeight = queryAll('.InPageSubNav-itemSubNav > li', targetEl)
  //       .map(el => getElHeight(el)).reduce((l, r) => l + r)
  //
  //     targetEl.classList.add('is-expanded')
  //     targetList.style.maxHeight = `${innerHeight}px`
  //   }
  // },
  //
  // findAncestor (el, cls) {
  //   while ((el = el.parentElement) && !el.classList.contains(cls));
  //
  //   return el;
  // },

  cacheDimensions () {
    this.deviceState = MediaQueries.getDeviceState().split(' ')[0]
  },

  onScroll (yPos) {
    this.yPos = yPos

    if (!this.reposition && (yPos >= this.RESPONSIVE_THRESHOLD[this.deviceState])) {
      this.appView.body.classList.add('sticky-inpage-nav')
    } else {
      this.appView.body.classList.remove('sticky-inpage-nav')
    }
  },

  onResize () {
    this.cacheDimensions()

    this.onScroll(this.yPos)

    if (MediaQueries.isSmallerThanBreakpoint(MediaQueries.DESKTOP)) {
      this.reposition = false
    } else {
      this.reposition = true
    }
  }
})

export default InPageSubNav
