import AbstractViewPage from 'views/abstract/AbstractViewPage'

const ArticleDetail = AbstractViewPage.extend({
  page: 'ArticleDetail',

  constructor (config = {}) {
    ArticleDetail.__super__.constructor.call(this, config)
  }
})

export default ArticleDetail
