import assignIn from 'lodash.assignin'

import AppView from 'views/AppView'
import AppRouter from 'router/AppRouter'
import AppModel from 'models/AppModel'
import UrlHelper from 'common/UrlHelper'
import Analytics from 'common/Analytics'

import Channel from 'common/Channel'
import Constants from 'common/Constants'

class App {
  start () {
    console.log('App:start()')

    this.removeGlobalErrorHandler()

    const appView = AppView.getInstance()
    const appRouter = AppRouter.getInstance()
    const appModel = AppModel.getInstance(JSON.parse(appView.el.getAttribute('data-app-model')))

    UrlHelper.setup()

    if (process.env.NODE_ENV !== 'production') {
      assignIn(window, {
        appView,
        appModel,
        appRouter,
        Constants,
        Channel
      })
    }

    appRouter.start()

    Analytics.start()
  }

  removeGlobalErrorHandler () {
    clearTimeout(window.APP_ERROR.timer)
    window.removeEventListener('error', window.APP_ERROR.errorFn, false)
  }
}

export default App
