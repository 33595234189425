import AbstractView from 'views/abstract/AbstractView'

import Scroller from 'common/Scroller'

const CategoryHeader = AbstractView.extend({
  template: 'CategoryHeader',

  events: {
    'click [data-services]': 'onServicesClick'
  },

  constructor (config = {}) {
    CategoryHeader.__super__.constructor.call(this, config)

    this.servicesAnchor = this.query('[data-services]')
  },

  onServicesClick (e) {
    e && e.preventDefault() && e.stopImmediatePropagation()

    const hash = this.servicesAnchor.href.replace(/.*(#.*)/, '$1')
    const target = document.querySelector(hash)
    const offset = -180

    Scroller.scrollTo({ offset, target })
  }
})

export default CategoryHeader
