import AbstractViewPage from 'views/abstract/AbstractViewPage'

const ServicesCategory = AbstractViewPage.extend({
  page: 'ServicesCategory',

  constructor (config = {}) {
    ServicesCategory.__super__.constructor.call(this, config)
  }
})

export default ServicesCategory
