import AbstractViewPage from 'views/abstract/AbstractViewPage'

const OurTeam = AbstractViewPage.extend({
  page: 'OurTeam',

  constructor (config = {}) {
    OurTeam.__super__.constructor.call(this, config)
  }
})

export default OurTeam
