import TWEEN from 'tween.js'

import AppView from 'views/AppView'

import { getElOffset, getElHeight } from 'utils/DOM'

const defaults = {
  offset: 0,
  minTime: 100,
  maxTime: 600
}

const MAX_DIST = 500

const Scroller = {
  scrolling: false,

  currentTween: null,

  scrollTo(settings, cb) {
    const offset = settings.offset || defaults.offset
    const maxTime = settings.maxTime || defaults.maxTime
    const minTime = settings.minTime || defaults.minTime

    const appView = AppView.getInstance()
    const appViewHeight = getElHeight(appView.el)

    let target = (typeof settings.target === 'number' ? settings.target : getElOffset(settings.target)) + offset
    target = Math.min((appViewHeight - appView.dimensions.height), target)

    let distToGo = appView.lastScrollY - target
    distToGo = (distToGo < 0) ? distToGo * -1 : distToGo

    let time

    if (distToGo === 0) {
      time = 0
    } else if (distToGo > MAX_DIST) {
      time = maxTime + minTime
    } else {
      time = ((distToGo / MAX_DIST) * maxTime) + minTime
    }

    const state = { y: appView.lastScrollY }
    const tween = new TWEEN.Tween(state)
      .to({ y: target }, time)
      .onUpdate(function () {
        window.scrollTo(0, this.y)
      })
      .onStart(() => {
        Scroller.scrolling = true
      })
      .onComplete(() => {
        Scroller.scrolling = false
        if (cb && typeof cb === 'function') cb()
      })
      .easing(TWEEN.Easing.Cubic.InOut)
      .start()

      Scroller.update()
  },

  update() {
    TWEEN.update()
    if (Scroller.scrolling) requestAnimationFrame(Scroller.update)
  }
}

export default Scroller
