const Constants = {}

Constants.EVENT_SCROLL = 'EVENT_SCROLL'
Constants.EVENT_SCROLL_END = 'EVENT_SCROLL_END'
Constants.EVENT_RESIZE = 'EVENT_RESIZE'
Constants.EVENT_BREAKPOINT_CHANGE = 'EVENT_BREAKPOINT_CHANGE'

Constants.EVENT_APP_START = 'EVENT_APP_START'
Constants.EVENT_CHANGE_VIEW_START = 'EVENT_CHANGE_VIEW_START'
Constants.EVENT_CHANGE_VIEW_COMPLETE = 'EVENT_CHANGE_VIEW_COMPLETE'
Constants.EVENT_CHANGE_PARTIAL_VIEW = 'EVENT_CHANGE_PARTIAL_VIEW'
Constants.EVENT_CHANGE_PAGE = 'EVENT_CHANGE_PAGE'

Constants.EVENT_UPDATE_SCROLL_ITEMS = 'EVENT_UPDATE_SCROLL_ITEMS'

Constants.EVENT_HASH_CHANGED = 'EVENT_HASH_CHANGED'
Constants.EVENT_ANCHOR_CHANGED = 'EVENT_ANCHOR_CHANGED'

Constants.EVENT_PAGE_LOAD_START = 'EVENT_PAGE_LOAD_START'
Constants.EVENT_PAGE_LOAD_PROGRESS = 'EVENT_PAGE_LOAD_PROGRESS'
Constants.EVENT_PAGE_LOAD_END = 'EVENT_PAGE_LOAD_END'
Constants.EVENT_PAGE_LOAD_ABORT = 'EVENT_PAGE_LOAD_ABORT'
Constants.EVENT_PAGE_LOAD_ERROR = 'EVENT_PAGE_LOAD_ERROR'

Constants.EVENT_SHOW_GLOBAL_LOADING = 'EVENT_SHOW_GLOBAL_LOADING'
Constants.EVENT_HIDE_GLOBAL_LOADING = 'EVENT_HIDE_GLOBAL_LOADING'

Constants.EVENT_COLLAPSE_HEADER = 'EVENT_COLLAPSE_HEADER'
Constants.EVENT_EXPAND_HEADER = 'EVENT_EXPAND_HEADER'

Constants.EVENT_COLLAPSE_DETAIL_HEADER = 'EVENT_COLLAPSE_DETAIL_HEADER'
Constants.EVENT_EXPAND_DETAIL_HEADER = 'EVENT_EXPAND_DETAIL_HEADER'

Constants.EVENT_OPEN_MENU = 'EVENT_OPEN_MENU'
Constants.EVENT_CLOSE_MENU = 'EVENT_CLOSE_MENU'

Constants.EVENT_OPEN_CONTACT_FORM = 'EVENT_OPEN_CONTACT_FORM'
Constants.EVENT_CLOSE_CONTACT_FORM = 'EVENT_CLOSE_CONTACT_FORM'

Constants.EVENT_SEARCH_KEYUP = 'EVENT_SEARCH_KEYUP'

Constants.RECENT_SEARCHES = 'recent_searches'

Constants.SCROLL_THROTTLE = 25
Constants.SCROLL_END_THROTTLE = 50
Constants.SCROLL_THRESHOLD = 1.15
Constants.SCROLL_SHOW_TIMEOUT = 150

Constants.RESIZE_DEBOUNCE = 250

Constants.SCROLL_ITEM_SELECTOR = 'data-scroll-item'
Constants.SCROLL_ITEM_SHOW = 'show-from-scroll'
Constants.SCROLL_ITEM_EVENT = 'data-scroll-item-event'

Constants.SLIDER_SLIDE_SPEED = 350
Constants.SLIDER_REWIND_SPEED = 700
Constants.SLIDER_SNAPBACK_SPEED = 300
Constants.SLIDER_EASE = 'easeOutCubic'

Constants.SKIP_KEYS = [16, 17, 18, 91, 93]

Constants.REQUEST_VIEW_DIMENSIONS = 'REQUEST_VIEW_DIMENSIONS'
Constants.REQUEST_PAGE_MODEL = 'REQUEST_PAGE_MODEL'
Constants.REQUEST_CURRENT_VIEW = 'REQUEST_CURRENT_VIEW'
Constants.REQUEST_CURRENT_URL = 'REQUEST_CURRENT_URL'
Constants.REQUEST_CURRENT_QUERY = 'REQUEST_CURRENT_QUERY'

Constants.TRANSITION_END = 'transitionend'

Constants.KEYWORD_QUERY_KEYS = ['keywords']

export default Constants
