import Swiper from 'swiper'
import merge from 'lodash.merge'
import assign from 'lodash.assign'
import Events from 'ampersand-events'

import AbstractView from 'views/abstract/AbstractView'
import Constants from 'common/Constants'

export const events = {
  INIT: 'INIT',
  MOVE: 'MOVE',
  CHANGE_END: 'CHANGE_END'
}

const Slider = AbstractView.extend({
  template: 'Slider',

  slider: null,
  frame: null,
  slides: null,

  config: {
    initialSlide: 0,
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,

    direction: 'horizontal',

    setWrapperSize: true,

    speed: Constants.SLIDER_SLIDE_SPEED,
    effect: 'slide',
    roundLengths: true,

    grabCursor: true,

    pagination: '[data-slider-pagination]',
    paginationBulletRender: (idx, className) => `<div class="${className}"></div>`,
    paginationFractionRender: (swiper, currentClass, totalClass) => `<span class="${currentClass}"></span> of <span class="${totalClass}"></span>`,
    paginationClickable: true,

    slideClass: 'Slider-slide',
    slideActiveClass: 'Slider-slide--active',
    slideVisibleClass: 'Slider-slide--visible',
    slideNextClass: 'Slider-slide--next',
    slidePrevClass: 'Slider-slide--prev',
    slideDuplicateClass: 'Slider-slide--duplicate',
    wrapperClass: 'Slider-frame',
    bulletClass: 'Slider-pagination--bullet',
    bulletActiveClass: 'Slider-pagination--active',
    paginationHiddenClass: 'Slider-pagination--hidden',
    paginationCurrentClass: 'Slider-pagination--current',
    paginationTotalClass: 'Slider-pagination--total',
    paginationProgressBarClass: 'Slider-pagination--progress',
    buttonDisabledClass: 'btn--disabled',

    prevButton: '.Slider-ctrl-prev',
    nextButton: '.Slider-ctrl-next',

    hashNav: false,

    enableEventOnMove: false,
    enableEventChangeEnd: false,

    initialiseWithSingleSlide: false
  },

  constructor (config = {}) {
    this._bindClassMethods()

    Slider.__super__.constructor.call(this, config)

    this.frame = this.query('[data-slider-frame]')
    this.slides = this.queryAll('[data-slider-frame] [data-slider-slide]')

    // need to create a new local copy as it will overwrite any original
    // properties due it being passed by reference
    let sliderConfig = merge({}, this.config)

    sliderConfig.onInit = this.onSliderInit

    // Make sure we only map/merge configs if we have a config object set
    if (this.el.hasAttribute('data-slider-conf') &&
      Object.keys(JSON.parse(this.el.getAttribute('data-slider-conf'))).length !== 0) {
      const elConf = JSON.parse(this.el.getAttribute('data-slider-conf'))

      sliderConfig = merge(sliderConfig, elConf)
    }

    if (sliderConfig.pagination) {
      this.pagination = sliderConfig.pagination = this.query(sliderConfig.pagination)
    }

    if (sliderConfig.scrollbar) {
      this.scrollbar = sliderConfig.scrollbar = this.query(sliderConfig.scrollbar)
    }

    if (sliderConfig.prevButton) {
      this.prevButton = sliderConfig.prevButton = this.query(sliderConfig.prevButton)
    }
    if (sliderConfig.nextButton) {
      this.nextButton = sliderConfig.nextButton = this.query(sliderConfig.nextButton)
    }

    if (sliderConfig.enableEventOnMove) {
      sliderConfig.onProgress = sliderConfig.onMove = this.onSliderMove
    }

    if (sliderConfig.enableEventChangeEnd) {
      sliderConfig.onSlideChangeEnd = this.onSlideChangeEnd
    }

    if ((this.el.classList.contains('is-stacked') || this.slides.length === 1) && !sliderConfig.initialiseWithSingleSlide) {
      return
    }

    this.slider = new Swiper(this.el, sliderConfig)
  },

  _bindClassMethods () {
    this.onSliderInit = this.onSliderInit.bind(this)
    this.onSliderMove = this.onSliderMove.bind(this)
    this.onSlideChangeEnd = this.onSlideChangeEnd.bind(this)
  },

  onSliderInit (instance) {
    this.slider = instance
    this.el.classList.add('Slider--initialized')

    this.el.getBoundingClientRect()

    this.trigger(events.INIT, instance)
  },

  onSliderMove (instance) {
    this.trigger(events.MOVE, instance)
  },

  onSlideChangeEnd (instance) {
    this.trigger(events.CHANGE_END, instance)
  },

  dispose () {
    if (this.slider) this.slider.destroy(true, true)

    Slider.__super__.dispose.call(this)

    this.slider = null
  }
})

export default assign(Slider, Events)
