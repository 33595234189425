import AbstractView from 'views/abstract/AbstractView'

// import Channel from 'common/Channel'
// import Constants from 'common/Constants'
// import MediaQueries from 'common/MediaQueries'

const ArticleHeader = AbstractView.extend({
//   template: 'ArticleHeader',
//
//   timeout: null,
//   mobile: false,
//
//   authorList: null,
//   authors: null,
//
//   WAIT: 3000,
//   HIDE_CLASS: 'ArticleHeader-author--hide',
//
//   constructor (config = {}) {
//     this._bindClassMethods()
//
//     ArticleHeader.__super__.constructor.call(this, config)
//
//     this.listenTo(Channel, Constants.EVENT_RESIZE, this.onResize)
//
//     this.authorList = this.query('[data-authors]')
//     this.authors = this.queryAll('.ArticleHeader-author')
//
//     this.onResize()
//   },
//
//   _bindClassMethods () {
//     this.onResize = this.onResize.bind(this)
//   },
//
//   _setupMobileAuthors () {
//     if (!this.authorList) return
//
//     this.authors.forEach((el, idx) => {
//       if (idx === 0) return true
//
//       el.classList.add(this.HIDE_CLASS)
//     })
//
//     this.timeout = setTimeout(this.cycle.bind(this, 0, this.authors.length), this.WAIT)
//   },
//
//   _resetMobileAuthors () {
//     if (this.timeout) clearTimeout(this.timeout)
//
//     this.authors.forEach(el => el.classList.remove(this.HIDE_CLASS))
//   },
//
//   cycle (current, total) {
//     if (this.timeout) clearTimeout(this.timeout)
//
//     const next = (current + 1) >= total ? 0 : (current + 1)
//
//     this.authors[current].classList.add(this.HIDE_CLASS)
//     setTimeout(_ => this.authors[next].classList.remove(this.HIDE_CLASS), 200)
//
//     this.timeout = setTimeout(this.cycle.bind(this, next, total), this.WAIT)
//   },
//
//   onResize () {
//     if (this.authors.length > 1 && MediaQueries.isSmallerThanBreakpoint(MediaQueries.TABLETPORTRAIT) && !this.mobile) {
//       this.mobile = true
//       this._setupMobileAuthors()
//     } else if (MediaQueries.isLargerThanBreakpoint(MediaQueries.TABLETPORTRAIT) && this.mobile) {
//       this.mobile = false
//       this._resetMobileAuthors()
//     }
//   }
})
//
export default ArticleHeader
