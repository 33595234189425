import AbstractViewPage from 'views/abstract/AbstractViewPage'

const EventsLanding = AbstractViewPage.extend({
  page: 'EventsLanding',

  constructor (config = {}) {
    EventsLanding.__super__.constructor.call(this, config)
  }
})

export default EventsLanding
