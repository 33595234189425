import AbstractView from 'views/abstract/AbstractView'

const InputText = AbstractView.extend({
  template: 'InputText',

  events: {
    'focus .InputText-input': 'onFocus',
    'blur .InputText-input': 'onBlur'
  },

  input: null,

  constructor (config = {}) {
    InputText.__super__.constructor.call(this, config)

    this.input = this.query('.InputText-input')
    this.label = this.query('.InputText-label')
  },

  onFocus () {
    this.el.classList.add('is-active', 'is-filled')
  },

  onBlur () {
    this.el.classList.remove('is-active')

    if (this.input.value.trim().length === 0) {
      this.el.classList.remove('is-filled')
    }
  },

  getValue () {
    const name = this.input.name
    const val = this.input.value
    const ret = {}

    ret[name] = val

    return ret
  }
})

export default InputText
