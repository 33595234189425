import assign from 'lodash.assign'
import findIndex from 'lodash.findindex'
import Fuse from 'fuse.js'
import template from 'lodash.template'
// import scrollbar from 'perfect-scrollbar';

import AbstractView from 'views/abstract/AbstractView'
import Channel from 'common/Channel'
import Constants from 'common/Constants'
import MediaQueries from 'common/MediaQueries'
import { closest, triggerEvent } from 'utils/DOM'

import selectTmpl from './Select/index.tmpl.js'

const Select = AbstractView.extend({
  template: 'Select',

  READY_CLASS: 'is-initialized',
  OPEN_CLASS: 'is-open',
  HAS_SELECTED_OPTIONS_CLASS: 'has-selection',
  ACTIVE_OPTION_CLASS: 'is-active',
  SELECTED_OPTION_CLASS: 'is-selected',
  HIDE_OPTION_CLASS: 'is-hidden',
  HEIGHT_TIMEOUT: 300,

  events: {
    'click [data-display]': 'onDisplayClick',
    'focus [data-display]': 'onDisplayFocus',
    'blur [data-display]': 'onDisplayBlur',
    'click [data-option]': 'onOptionClick',
    'mouseover [data-option]': 'onOptionMouseOver',
    'mouseout [data-option]': 'onOptionMouseLeave',

    'keyup [data-filter-input]': 'onFilterInputKeyUp',
    'focus [data-filter-input]': 'onFilterInputFocus',
    'blur [data-filter-input]': 'onFilterInputBlur',

    'change .Select--no-js select': 'onSelectChange'
  },

  isReady: false,
  isOpen: false,
  fuse: undefined,
  realOptions: [],
  options: [],
  activeOptionIndex: undefined,
  selectedOptionsIndexes: [],
  selectedDisplay: undefined,
  queryParam: {},

  // config
  config: {
    className: 'Select',
    multi: false,
    displaySelectedOptionWhenOpen: false,
    displaySelectedOptionWhenClosed: false,
    filter: {
      show: false,
      placeholder: null
    },
    options: [],
    name: undefined
  },

  init () {
    this._bindClassMethods()
    this._bindEvents()

    this.config = assign({}, this.config, JSON.parse(this.el.getAttribute('data-config')))

    this.realSelect = this.query('select')
    this.realOptions = this.queryAll('option')

    if (!this.config.multi) this.realOptions.shift()

    this._insertMarkup()

    this.options = this.queryAll('[data-option]')
    this.selectedDisplay = this.query('[data-display-selected]')

    this._setExistingValues()

    if (this.config.filter.show) this._initFuse()
    this._setReady()

    // this.scrollbarEl = this.query('[data-content]');
    // this.scrollbar = scrollbar.initialize(this.scrollbarEl);
  },

  _bindClassMethods () {
    this.onDocumentClick = this.onDocumentClick.bind(this)
    this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this)
    this.onGlobalLoadingShow = this.onGlobalLoadingShow.bind(this)
  },

  _bindEvents () {
    document.addEventListener('click', this.onDocumentClick)
    document.addEventListener('keydown', this.onDocumentKeyDown)
    this.listenTo(Channel, Constants.EVENT_SHOW_GLOBAL_LOADING, this.onGlobalLoadingShow)
  },

  clearValues () {
    this.selectedOptionsIndexes = []
    this._setSelectedOption(true)
  },

  getValue () {
    const value = {}

    value[this.config.name] = this.realOptions
      .filter(option => option.selected)
      .map(option => option.value)

    if (value[this.config.name].length === 0) {
      return false
    }

    if (!this.config.multi) {
      value[this.config.name] = value[this.config.name][0]
    }

    return value
  },

  onGlobalLoadingShow () {
    setTimeout(_ => {
      if (this.isOpen) {
        this._toggleOpen()
      }
    })
  },

  _initFuse () {
    this.fuse = new Fuse(this.config.options, { keys: ['display'], threshold: 0.4 })
  },

  _insertMarkup () {
    const fake = document.createElement('div')
    fake.innerHTML = template(selectTmpl)(this.config)

    this.el.appendChild(fake.querySelector('div'))
  },

  _setExistingValues () {
    this.selectedOptionsIndexes = this.realOptions.reduce((arr, option, i) => {
      return option.selected ? [...arr, i] : arr
    }, [])

    this._setSelectedOption()
  },

  _setReady () {
    this.el.classList.add(this.READY_CLASS)
    this.isReady = true
  },

  _toggleOpen (force = false) {
    //if (MediaQueries.isSmallerThanBreakpoint(MediaQueries.TABLETPORTRAIT)) {
    if (MediaQueries.isSmallerThanBreakpoint(MediaQueries.DESKTOP)) {
      // this.realSelect.focus()
      return
    }

    this.isOpen = force === 'open' || (force === false && !this.isOpen)
    const height = this.isOpen ? this.query('[data-content-inner]').clientHeight : 0

    this.el.classList[this.isOpen ? 'add' : 'remove'](this.OPEN_CLASS)
    window.setTimeout(() => {
      this.query('[data-content]').style.height = height !== 0 ? `${height}px` : ''
    }, !this.isOpen ? this.HEIGHT_TIMEOUT : 0)

    this._setActiveOption(undefined)
  },

  onDisplayClick () {
    this._toggleOpen()
  },

  onDisplayFocus () {
    this.isFocused = true
  },

  onDisplayBlur () {
    this.isFocused = false
  },

  onDocumentClick (e) {
    if (this.isOpen && !closest(e.target, (node) => node === this.el)) {
      e.preventDefault()
      this._toggleOpen()
    }
  },

  onDocumentKeyDown (e) {
    switch (e.keyCode) {
      case 13: // ENTER
        if (this.isOpen) {
          e.preventDefault()
          this._setSelectedOption()
          this._toggleOpen()
        }

        break
      case 27: // ESC
        if (this.isOpen) {
          e.preventDefault()
          this._toggleOpen()
        }

        break
      case 38: // UP
        if (!this.isOpen && this.isFocused) this._toggleOpen()

        if (this.isOpen) {
          e.preventDefault()
          this._setActiveOption('up')
        }

        break
      case 40: // DOWN
        if (!this.isOpen && this.isFocused) this._toggleOpen()

        if (this.isOpen) {
          e.preventDefault()
          this._setActiveOption('down')
        }

        break
    }
  },

  onOptionMouseOver (e) {
    this._setActiveOption(this.options.indexOf(e.delegateTarget))
  },

  onOptionMouseLeave () {
    this._setActiveOption(null)
  },

  _setActiveOption (index) {
    if (index === 'up') {
      if (this.activeOptionIndex === undefined) {
        this.activeOptionIndex = this.options.length - 1
      } else {
        this.activeOptionIndex = (this.activeOptionIndex - 1) < 0 ? (this.options.length - 1) : (this.activeOptionIndex - 1)
      }
    } else if (index === 'down') {
      if (this.activeOptionIndex === undefined) {
        this.activeOptionIndex = 0
      } else {
        this.activeOptionIndex = (this.activeOptionIndex + 1) > (this.options.length - 1) ? 0 : (this.activeOptionIndex + 1)
      }
    } else if (index === undefined) {
      this.activeOptionIndex = undefined
    } else {
      this.activeOptionIndex = index
    }

    this.options.forEach((option, i) => {
      const classType = i === this.activeOptionIndex ? 'add' : 'remove'
      option.classList[classType](this.ACTIVE_OPTION_CLASS)
    })
  },

  onOptionClick () {
    this._setSelectedOption()
    if (!this.config.multi) this._toggleOpen()
  },

  _setSelectedOption (clear = false) {
    if (!clear) {
      this._setSelectedOptionsIndexes()
    }

    this._setOptions(clear)
  },

  _setOptions (clear) {
    this.options.forEach((option, i) => {
      const isSelected = this.selectedOptionsIndexes.indexOf(i) !== -1
      option.classList[isSelected ? 'add' : 'remove'](this.SELECTED_OPTION_CLASS)

      // if (MediaQueries.getDeviceState() !== MediaQueries.DEFAULT) {
      if (MediaQueries.getDeviceState() === MediaQueries.DESKTOP) {
        if (isSelected) {
          this.realOptions[i].setAttribute('selected', 'selected')
        } else {
          this.realOptions[i].removeAttribute('selected')
        }
      }
    })

    // if (MediaQueries.getDeviceState() !== MediaQueries.DEFAULT) {
    if (MediaQueries.getDeviceState() === MediaQueries.DESKTOP) {
      if (clear) {
        triggerEvent(this.realSelect, 'clear', true)
      } else {
        triggerEvent(this.realSelect, 'change', true)
      }
    }

    this._setDisplayText()
  },

  _setSelectedOptionsIndexes () {
    if (typeof this.activeOptionIndex !== 'number') return

    if (this.config.multi) {
      if (this.selectedOptionsIndexes.indexOf(this.activeOptionIndex) !== -1) {
        this.selectedOptionsIndexes = this.selectedOptionsIndexes.filter(i => i !== this.activeOptionIndex)
      } else {
        this.selectedOptionsIndexes.push(this.activeOptionIndex)
      }
    } else {
      if (this.selectedOptionsIndexes.indexOf(this.activeOptionIndex) !== -1) {
        this.selectedOptionsIndexes = []
      } else {
        this.selectedOptionsIndexes = [this.activeOptionIndex]
      }
    }
  },

  _setDisplayText () {
    const selectedOptionsIndexesLength = this.selectedOptionsIndexes.length

    if (selectedOptionsIndexesLength > 0) {
      this.el.classList.add(this.HAS_SELECTED_OPTIONS_CLASS)

      if (this.config.displaySelectedOptionWhenOpen || this.config.displaySelectedOptionWhenClosed) {
        this.selectedDisplay.textContent = selectedOptionsIndexesLength > 1 ? `${selectedOptionsIndexesLength} selected` : this.options[this.selectedOptionsIndexes[0]].textContent
      }
    } else {
      this.el.classList.remove(this.HAS_SELECTED_OPTIONS_CLASS)
    }
  },

  // START FUSE
  onFilterInputKeyUp (e) {
    const val = e.target.value.trim()
    const results = val ? this.fuse.search(val) : this.config.options

    this.options.forEach(option => {
      const classType = findIndex(results, { value: option.getAttribute('data-option') }) === -1 ? 'add' : 'remove'
      option.classList[classType](this.HIDE_OPTION_CLASS)
    })
  },

  onFilterInputFocus (e) {},

  onFilterInputBlur (e) {},

  onSelectChange (e) {
    // if (!MediaQueries.isSmallerThanBreakpoint(MediaQueries.TABLETPORTRAIT)) return
    if (!MediaQueries.isSmallerThanBreakpoint(MediaQueries.DESKTOP)) return

    this._setExistingValues()
  },

  setValue (value) {
    this.selectedOptionsIndexes = this.realOptions.reduce((arr, option, i) => {
      return value.indexOf(option.value) !== -1 ? [...arr, i] : arr
    }, [])

    this._setSelectedOption()
  },

  // END FUSE

  _unbindEvents () {
    document.removeEventListener('click', this.onDocumentClick)
    document.removeEventListener('keydown', this.onDocumentKeyDown)
    this.stopListening(Channel, Constants.EVENT_SHOW_GLOBAL_LOADING, this.onGlobalLoadingShow)
  },

  dispose () {
    this._unbindEvents()
    Select.__super__.dispose.call(this)
  }
})

export default Select
