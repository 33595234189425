import AbstractViewPage from 'views/abstract/AbstractViewPage'

const Insights = AbstractViewPage.extend({
  page: 'Insights',

  constructor (config = {}) {
    Insights.__super__.constructor.call(this, config)
  }
})

export default Insights
