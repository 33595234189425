import AbstractView from 'views/abstract/AbstractView'

import Slider from 'views/includes/Slider'
import ViewFinder from 'common/ViewFinder'
import Channel from 'common/Channel'
import Constants from 'common/Constants'
import MediaQueries from 'common/MediaQueries'

const MIN_SLIDES_REQUIRED = {
  'regular': {
    'default': 2,
    'tabletportrait': 4,
    'tabletlandscape': 4,
    'desktop': 5
  },
  'contacts': {
    'default': 2,
    'tabletportrait': 2,
    'tabletlandscape': 2,
    'desktop': 3
  },
  'cases': {
    'default': 2,
    'tabletportrait': 2,
    'tabletlandscape': 2,
    'desktop': 2
  }
}

const ContentBlocks = AbstractView.extend({
  template: 'ContentBlocks',

  modules: [
    Slider
  ],

  slider: null,

  constructor (config = {}) {
    this._bindClassMethods()

    ContentBlocks.__super__.constructor.call(this, config)

    this.slider = ViewFinder.findChildren(this, Slider)[0]
    this.slidesTotal = this.slider ? this.slider.slides.length : 0

    this.type = this.el.getAttribute('data-block-types')

    if (this.type === 'people') {
      this.type = 'contacts'
    } else if (!MIN_SLIDES_REQUIRED.hasOwnProperty(this.type)) {
      this.type = 'regular'
    }

    this.listenTo(Channel, Constants.EVENT_RESIZE, this.onResize)

    this.onResize()
  },

  _bindClassMethods () {
    this.onResize = this.onResize.bind(this)
  },

  onResize () {
    const deviceState = MediaQueries.getDeviceState().split(' ')[0]

    if (this.slidesTotal < MIN_SLIDES_REQUIRED[this.type][deviceState]) {
      this.el.classList.add('ContentBlocks--hideControls')
    } else if (this.slidesTotal >= MIN_SLIDES_REQUIRED[this.type][deviceState]) {
      this.el.classList.remove('ContentBlocks--hideControls')
    }
  }
})

export default ContentBlocks
