import AbstractViewPage from 'views/abstract/AbstractViewPage'

const CaseStudiesDetail = AbstractViewPage.extend({
  page: 'CaseStudiesDetail',

  constructor (config = {}) {
    CaseStudiesDetail.__super__.constructor.call(this, config)
  }
})

export default CaseStudiesDetail
