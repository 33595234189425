import HeroHome from './HeroHome'
import ContactButton from './ContactButton'
import ContentBlocks from './ContentBlocks'
import GetStarted from './GetStarted'
import CategoryHeader from './CategoryHeader'
import DetailHeader from './DetailHeader'
import ArticleHeader from './ArticleHeader'
import InPageSearch from './InPageSearch'
import SearchResultHeader from './SearchResultHeader'
import SearchResultsContent from './SearchResultsContent'
import SearchFilter from './SearchFilter'
import GlobalLocationsMap from './LocationsMap/index';

export default [
  HeroHome,
  ContactButton,
  ContentBlocks,
  GetStarted,
  CategoryHeader,
  DetailHeader,
  ArticleHeader,
  InPageSearch,
  SearchResultHeader,
  SearchResultsContent,
  SearchFilter,
  GlobalLocationsMap,
]
