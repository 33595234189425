import AbstractViewPage from 'views/abstract/AbstractViewPage'

const LocationDetail = AbstractViewPage.extend({
  page: 'LocationDetail',

  constructor (config = {}) {
    LocationDetail.__super__.constructor.call(this, config)
  }
})

export default LocationDetail
