/* global Modernizr */

// http://stackoverflow.com/a/14223920
function iOSVersion () {
  const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
  return [ parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10) ]
}

// http://stackoverflow.com/a/4900484
function chromeVersion () {
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)
  return raw ? parseInt(raw[2], 10) : false
}

// http://stackoverflow.com/a/17907562
function getInternetExplorerVersion () {
  var rv = -1
  if (navigator.appName == 'Microsoft Internet Explorer') {
    var ua = navigator.userAgent
    var re = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})')
    if (re.exec(ua) != null) { rv = parseFloat(RegExp.$1) }
  } else if (navigator.appName == 'Netscape') {
    var ua = navigator.userAgent
    var re = new RegExp('Trident/.*rv:([0-9]{1,}[\.0-9]{0,})')
    if (re.exec(ua) != null) { rv = parseFloat(RegExp.$1) }
  }
  return rv
}

const Device = {
  ua: window.navigator.userAgent.toLowerCase(),
  tests: {
    // UA sniffing
    'ios': () => {
      return (/ipad|iphone|ipod/).test(Device.ua) && !window.MSStream
    },
    'iphone': () => {
      return (/iphone/).test(Device.ua) && !window.MSStream
    },
    'android': () => {
      return Device.ua.indexOf('android') > -1
    },
    'ios-or-android': () => {
      return (/iphone/).test(Device.ua) && !window.MSStream || Device.ua.indexOf('android') > -1
    },
    'safari': () => {
      return Device.ua.indexOf('safari') > -1 && Device.ua.indexOf('chrome') === -1
    },
    'firefox': () => {
      return Device.ua.indexOf('firefox') > -1
    },
    'chrome': () => {
      return (/chrome/).test(Device.ua) && (/Google Inc/).test(navigator.vendor)
    },
    'chrome_ios': () => {
      return Device.ua.match('crios') // http://stackoverflow.com/a/13808053
    },
    'windows': () => {
      return Device.ua.indexOf('windows') > -1 && Device.ua.indexOf('windows phone') < 0
    },
    'osx': () => {
      return navigator.platform.toLowerCase().indexOf('mac') > -1
    },
    'old_ie': () => {
      return document.documentElement.classList.contains('lte-ie9')
    },
    'ie11': () => {
      return getInternetExplorerVersion() === 11
    },

    // general features
    'xhr2': () => {
      return 'FormData' in window
    },

    'webkit-text-stroke': () => {
      const h1 = document.createElement('h1')
      return !(!('webkitTextStroke' in h1.style) && !('textStroke' in h1.style))
    },

    app_scroll_transition: () => {
      return Modernizr.csstransitions && Modernizr.csstransforms3d
    },

    app_page_transitions: () => {
      // TEMP - not ready for this yet
      // return Modernizr.history && Modernizr.csstransitions
      return false
    },

    'mix_blend_mode': () => {
      // Old iOS / chrome have patchy support for mix-blend-mode,
      // so we exclude them from the support list here.

      if (Modernizr.ios && iOSVersion()[0] < 9) {
        return false
      }

      if (Modernizr.chrome && chromeVersion() < 47) {
        return false
      }

      return Modernizr.testProp('mixBlendMode')
    },

    'font_smoothing': () => {
      return Modernizr.ios || Modernizr.osx || Modernizr.android
    }

  },

  setup () {
    for (let key in Device.tests) {
      Modernizr.addTest(key, Device.tests[key])
    }

    Device._checkForRemove()
    Device._objectFitFallback();
  },

  // https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
  _checkForRemove () {
    if (!('remove' in Element.prototype)) {
      Element.prototype.remove = function () {
        if (this.parentNode) {
          this.parentNode.removeChild(this)
        }
      }
    }
  },
  _objectFitFallback() {
    var imgList,
      length,
      url,
      i;
    if (!Modernizr.objectfit) {
      imgList = document.querySelectorAll('.u-img--objectFit')
      length = imgList.length
      if (length) {
        for (i = 0; i < length; i++) {
          url = imgList[i].getAttribute('src')
          imgList[i].setAttribute('style', 'display: none;')
          imgList[i].parentElement.setAttribute('style', 'background: url(' + url + ') 50% 50%; background-size: cover;')
        }
      }
    }
  }
}

export default Device
