import AbstractView from 'views/abstract/AbstractView'

// import Channel from 'common/Channel'
// import Constants from 'common/Constants'
// import MediaQueries from 'common/MediaQueries'
// import { getElOffset, getElHeight } from 'utils/DOM'

import InPageSubNav from 'views/components/InPageSubNav'

const DetailHeader = AbstractView.extend({
  template: 'DetailHeader',

  modules: [
    InPageSubNav
  ],

  reposition: true,
  offset: 0,
  body: null,

  STICKY_OFFSET: 36,
  STICKY_IMAGE_HEIGHT: 180,
  THRESHOLD: 180,

  constructor (config = {}) {
    this._bindClassMethods()

    DetailHeader.__super__.constructor.call(this, config)

    if (Modernizr && !Modernizr.csspositionsticky && Window['Stickyfill']) {
      Stickyfill.add(this.el)
      Stickyfill.init()
    }

    // this.listenTo(Channel, Constants.EVENT_SCROLL, this.onScroll)
    // this.listenTo(Channel, Constants.EVENT_RESIZE, this.onResize)
    // this.listenTo(Channel, Constants.EVENT_COLLAPSE_HEADER, this.cacheDimensions)
    // this.listenTo(Channel, Constants.EVENT_EXPAND_HEADER, this.cacheDimensions)
  },

  _bindClassMethods () {
    this.onScroll = this.onScroll.bind(this)
    this.onResize = this.onResize.bind(this)
    this.cacheDimensions = this.cacheDimensions.bind(this)
  },

  cacheDimensions () {
  },

  onScroll (yPos) {
  },

  onResize () {
  },

  dispose () {
    if (Modernizr && Modernizr.ie11) {
      Stickyfill.kill()
    }
  }
})

export default DetailHeader
