import AbstractViewPage from 'views/abstract/AbstractViewPage'

const PeopleDetail = AbstractViewPage.extend({
  page: 'PeopleDetail',

  constructor (config = {}) {
    PeopleDetail.__super__.constructor.call(this, config)
  }
})

export default PeopleDetail
