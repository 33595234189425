import AbstractViewPage from 'views/abstract/AbstractViewPage'

const WebcastsListing = AbstractViewPage.extend({
  page: 'WebcastsListing',

  constructor (config = {}) {
    WebcastsListing.__super__.constructor.call(this, config)
  }
})

export default WebcastsListing
