import AbstractViewPage from 'views/abstract/AbstractViewPage'

const GlobalLocations = AbstractViewPage.extend({
  page: 'GlobalLocations',

  constructor (config = {}) {
    GlobalLocations.__super__.constructor.call(this, config)
  }
})

export default GlobalLocations
