import AbstractViewPage from 'views/abstract/AbstractViewPage'

const CaseStudiesListing = AbstractViewPage.extend({
  page: 'CaseStudiesListing',

  constructor (config = {}) {
    CaseStudiesListing.__super__.constructor.call(this, config)
  }
})

export default CaseStudiesListing
