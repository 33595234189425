import assign from 'lodash.assign'

import AppModel from 'models/AppModel'
import AppRouter from 'router/AppRouter'

import Singleton from 'common/Singleton'
import Constants from 'common/Constants'
import Channel from 'common/Channel'
import Analytics from 'common/Analytics'
import MediaQueries from 'common/MediaQueries'

import AbstractView from 'views/abstract/AbstractView'

import Search from 'views/components/Search'
import ContactButton from 'views/components/ContactButton'
import CountrySelect from 'views/components/CountrySelect'
import MobileNav from 'views/includes/MobileNav'

const Header = AbstractView.extend({
  template: 'Header',

  modules: [
    ContactButton,
    Search,
    CountrySelect
  ],

  navItemTimer: null,
  activeNavItem: undefined,
  activeItem: undefined,

  MENU_OPEN_CLASS: 'menu-is-open',
  SCROLLED_CLASS: 'is-scrolled',
  ACTIVE_ITEM_TIMEOUT: 200,
  ACTIVE_ITEM_CLASS: 'is-active',

  SCROLL_THRESHOLD: 160,

  lastScrollY: 0,

  events: {
    'click [data-toggle-menu-button]': 'onMenuToggleClick',
    'click [data-contact-button]': _ => Channel.trigger(Constants.EVENT_OPEN_CONTACT_FORM),

    'click [data-nav-item] > a': 'onHeaderMenuListItemClick',
    'click [data-nav-item] .Header-subNav a': 'onHeaderSubMenuListItemClick'
  },

  constructor () {
    this._bindClassMethods()

    Header.__super__.constructor.call(this)

    this.currentNavItem = this.query('[data-nav-item].is-active')
    this.navItems = this.queryAll('[data-nav-item]')
    this.mobileNav = MobileNav.getInstance()

    this.primaryNavItems = this.queryAll('[data-nav-item]').map(item => {
      const el = item
      const link = item.querySelector('a')
      const re = new RegExp(`^${link.href}`, 'i')
      return { el, re }
    })

    this.body = document.body
  },

  init () {
    this.listenTo(Channel, Constants.EVENT_SCROLL, this.onScroll)
    this.listenTo(Channel, Constants.EVENT_SCROLL_END, this.onScrollEnd)
    this.listenTo(Channel, Constants.EVENT_RESIZE, this.onResize)
    this.listenTo(Channel, Constants.EVENT_HASH_CHANGED, this.onHashChanged)
    this.listenTo(Channel, Constants.EVENT_CHANGE_VIEW_COMPLETE, this.onChangeViewEnd)
    this.listenTo(Channel, Constants.EVENT_OPEN_CONTACT_FORM, _ => {
      this.body.classList.remove(this.MENU_OPEN_CLASS)
    })
  },

  _bindClassMethods () {
    this.onScroll = this.onScroll.bind(this)
    this.onScrollEnd = this.onScrollEnd.bind(this)
    this.onResize = this.onResize.bind(this)
    this.hideSubNav = this.hideSubNav.bind(this)
    this.showSubNav = this.showSubNav.bind(this)
    this.onHashChanged = this.onHashChanged.bind(this)
    this.onChangeViewEnd = this.onChangeViewEnd.bind(this)
  },

  showSubNav (el) {
    this.body.classList.add(this.SUBNAV_OPEN_CLASS)

    this.activeBarEnterTimeout = undefined
  },

  hideSubNav () {
    this.body.classList.remove(this.SUBNAV_OPEN_CLASS)

    this.activeBarLeaveTimeout = undefined
  },

  setActiveNavItem (e) {
    this.currentActiveItem = e.target
  },

  onMenuToggleClick () {
    this.body.classList.toggle(this.MENU_OPEN_CLASS)

    this.menuOpen = this.body.classList.contains(this.MENU_OPEN_CLASS)

    const evt = this.menuOpen ? Constants.EVENT_OPEN_MENU : Constants.EVENT_CLOSE_MENU

    Channel.trigger(evt)
  },

  onScrollEnd (yPos) {
    this.lastScrollY = yPos
  },

  onScroll (yPos) {
    if (MediaQueries.isSmallerThanBreakpoint(MediaQueries.TABLETPORTRAIT)) return

    if (MediaQueries.isSmallerThanBreakpoint(MediaQueries.DESKTOP)) {
      if (yPos < this.lastScrollY && this.body.classList.contains(this.SCROLLED_CLASS)) {
        this.body.classList.remove(this.SCROLLED_CLASS)
        Channel.trigger(Constants.EVENT_EXPAND_HEADER)
      }

      if (yPos < this.lastScrollY) return
    }

    if (yPos > this.SCROLL_THRESHOLD && !this.body.classList.contains(this.SCROLLED_CLASS)) {
      this.body.classList.add(this.SCROLLED_CLASS)
      Channel.trigger(Constants.EVENT_COLLAPSE_HEADER)
    } else if (yPos < this.SCROLL_THRESHOLD && this.body.classList.contains(this.SCROLLED_CLASS)) {
      this.body.classList.remove(this.SCROLLED_CLASS)
      Channel.trigger(Constants.EVENT_EXPAND_HEADER)
    }
  },

  onResize () {
    if (MediaQueries.isLargerThanBreakpoint(MediaQueries.TABLETPORTRAIT) && this.menuOpen) {
      this.onMenuToggleClick()
    }
  },

  onHeaderMenuListItemClick (e) {
    Analytics.action('navigation', 'header-click', e.delegateTarget.innerText.trim())
  },

  onHeaderSubMenuListItemClick (e) {
    Analytics.action('navigation', 'menu-click', e.delegateTarget.innerText.trim())
  },

  onHashChanged (current, previous, params) {
    if (params.FIRST_ROUTE) this.updateActivePrimaryNavItem()

    this.hideSubNav()
  },

  onChangeViewEnd () {
    this.updateActivePrimaryNavItem()
  },

  updateActivePrimaryNavItem () {
    const appRouter = AppRouter.getInstance()
    const appModel = AppModel.getInstance()
    const matchesCurrentRoute = this.primaryNavItems.filter(item => item.re.exec(`${appModel.get('baseUrl')}/${appRouter.current.route}`))

    this.primaryNavItems.forEach(item => item.el.classList.remove('is-active-section'))

    if (matchesCurrentRoute.length) matchesCurrentRoute[0].el.classList.add('is-active-section')
  },

  dispose () {
    Header.__super__.dispose.apply(this, arguments)

    this.removeEventListeners()
  }
})

export default assign(Header, Singleton)
