// Regex to find '+' symbol
const plus = /\+/g
// Regex to find key value pairs
const search = /([^&=]+)=?([^&]*)/g

function decode (str) {
  return decodeURIComponent(str.replace(plus, ' '))
}

export default function parseQueryString (query, freetextQueryKeys = []) {
  if (!query) return {}

  const params = {}

  let match = search.exec(query)

  while (match) {
    let key = decode(match[1])
    let val = decode(match[2])

    if (freetextQueryKeys.indexOf(key) === -1 && val.indexOf(',') > -1) {
      val = val.split(',')
    }

    params[key] = val
    match = search.exec(query)
  }

  // console.debug('parseQueryString:', query, JSON.stringify(params, null, 2));
  return params
}
