const DOM = {
  /**
   * Utility function to toggle classes on elements
   *
   * @prop el Element to toggle classes on
   * @prop cls String of
   */
  toggleClass(el, cls) {
    // split string on spaces to detect if multiple classes are passed in
    cls = cls.indexOf(' ') !== -1 ? cls.split(' ') : cls

    if (Array.isArray(cls)) {
      cls.forEach((_class) => DOM.toggleClass(el, _class))
      return
    }

    if (el.classList) {
      el.classList.toggle(cls)
    } else {
      const classes = el.className.split(' ')
      const existingIndex = classes.indexOf(cls)

      if (existingIndex >= 0) {
        classes.splice(existingIndex, 1)
      } else {
        classes.push(cls)
      }

      el.className = classes.join(' ')
    }
  },

  triggerEvent(el, type, bubbles = false , cancelable = true) {
    if ('createEvent' in document) {
      const e = document.createEvent('HTMLEvents')
      // NOTE: Apparently `initEvent` is deprecated in favour of using
      // custom event constructors instead
      // The `CustomEvent` currently has no support for the constructor
      // in IE, and only in the nightly build of Safari/WebKit
      e.initEvent(type, bubbles, cancelable)
      el.dispatchEvent(e)
    } else {
      const e = document.createEventObject()
      e.eventType = type
      el.fireEvent('on' + e.eventType, e)
    }
  },

  hasClass(element, cls) {
    return (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1
  },

  /**
   * Gets the element height including margins
   *
   * @prop el Element
   * @return int
   */
  getElHeight(el) {
    const h = el.offsetHeight
    const { marginTop, marginBottom } = getComputedStyle(el)

    return h + parseInt(marginTop, 10) + parseInt(marginBottom, 10)
  },

  getElWidth(el) {
    const w = el.offsetWidth
    const { marginLeft, marginRight } = getComputedStyle(el)

    return w + parseInt(marginLeft, 10) + parseInt(marginRight, 10)
  },

  /**
   * Utility to make sure we return an `Array` rather than `NodeList`
   * when querying for DOM nodes.
   *
   * @prop selector String
   * @return Array
   */
  queryAll(selector, outer = document) {
    return [].concat(Array.prototype.slice.call(outer.querySelectorAll(selector)))
  },

  asArray(nodeList) {
    return Array.prototype.slice.call(nodeList, 0)
  },

  /**
   * Replicate jQuery.closest
   */
  closest(el, fn) {
    return el && (
      fn(el) ? el : closest(el.parentNode, fn)
    )
  },

  setTransform(el, transformStr) {
    el.style.webkitTransform = transformStr
    el.style.MozTransform = transformStr
    el.style.msTransform = transformStr
    el.style.OTransform = transformStr
    el.style.transform = transformStr
  },

  getElOffset(el) {
    const pageScrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
    const { marginTop } = getComputedStyle(el)
    return Math.floor(el.getBoundingClientRect().top + pageScrollPos - parseInt(marginTop, 10))
  },

  insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
  }
}

export default DOM

export const toggleClass = DOM.toggleClass
export const triggerEvent = DOM.triggerEvent
export const insertAfter = DOM.insertAfter
export const hasClass = DOM.hasClass
export const asArray = DOM.asArray
export const getElHeight = DOM.getElHeight
export const getElWidth = DOM.getElWidth
export const queryAll = DOM.queryAll
export const closest = DOM.closest
export const setTransform = DOM.setTransform
export const getElOffset = DOM.getElOffset
