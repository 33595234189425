import assign from 'lodash.assign'

import Singleton from 'common/Singleton'

import AbstractView from 'views/abstract/AbstractView'

import CountrySelect from 'views/components/CountrySelect'
import Search from 'views/components/Search'

import { queryAll, getElHeight } from 'utils/DOM'

const MobileNav = AbstractView.extend({
  template: 'MobileNav',

  modules: [
    Search,
    CountrySelect
  ],

  events: {
    'click [data-toggle-section]': 'onToggleSection'
  },

  constructor (config = {}) {
    MobileNav.__super__.constructor.call(this)
  },

  onToggleSection (evt) {
    const targetEl = event.delegateTarget
    const targetList = targetEl.querySelector('ul')


    if (targetList) {
      if (targetEl.classList.contains('is-expanded')) {
        targetList.removeAttribute('style')
        targetEl.classList.remove('is-expanded')
        return
      }

      const innerHeight = queryAll('li', targetList)
        .map(el => getElHeight(el)).reduce((l, r) => l + r)

      targetEl.classList.add('is-expanded')
      targetList.style.maxHeight = `${innerHeight}px`
    }
  }
})

export default assign(MobileNav, Singleton)
